import React from "react";
import * as S from "./SidebarProfile.styles";
import copyImg from "../../../images/copy.png";

const SidebarProfile = (props) => {
  const show = true;

  return (
    <>
      <S.SidebarNavContainer>
        <S.DermProfileImg></S.DermProfileImg>
        <S.DermProfileName>DR. Orrey</S.DermProfileName>

        <S.SidebarNavItemContainer>
          <S.SidebarNavIcon></S.SidebarNavIcon>
          <S.StyledLink to="/dashboard">
            <S.SidebarNavItem>Dashboard</S.SidebarNavItem>
          </S.StyledLink>
        </S.SidebarNavItemContainer>

        <S.SidebarNavItemContainer>
          <S.SidebarNavIcon img={copyImg}></S.SidebarNavIcon>
          <S.StyledLink to="/patients">
            <S.SidebarNavItem>Patients</S.SidebarNavItem>
          </S.StyledLink>
        </S.SidebarNavItemContainer>

        <S.SidebarNavItemContainer>
          <S.SidebarNavIcon img={copyImg}></S.SidebarNavIcon>
          <a style={{  color: '#212529', textDecoration: 'none'}} href="https://www.quickspot.online/" target="blank">
            <S.SidebarNavItem>Create <br/>QuickSpot</S.SidebarNavItem>
          </a>
        </S.SidebarNavItemContainer>
      </S.SidebarNavContainer>
    </>
  );
};

export default SidebarProfile;
