import React, { useState, useEffect } from "react";
import * as S from "./EmailPreview.styles";

const RashEmailPreview = (props) => {
  const formData = props.formData;
  const requestData = props.requestData;

  const [previewData, setPreviewData] = useState("");
  const [form, setForm] = useState(null);

  const editableContent = {
    contentEditable: true,
    suppressContentEditableWarning: true,
    onKeyDown: breakline,
  };

  useEffect(() => {
    const defaultForm = {
      p1: "Dear ",
      p2: "Please see the results of your Quick Spot diagnosis below: ",
      p3: "Diagnosis: ",
      p4: "Medical Aid Code: ",
      p5: "To treat this condition, please see your script attached with the following instructions: ",
      p6: " repeats. ",
      p7: "No script is needed to treat this condition. ",
      p8: "I also recommend an additional script (attached) with the following instructions: ",
      p9: " repeats. ",
      p10: "",
      p11: "You can make a pop in appointment with me to have this issue addressed. The cost of which will be around R",
      p12: ". Please call my rooms on 021 797 4294 to make this appointment. ",
      p13: "",
      p14: "Dr Orrey recommends a ",
      p15: "We will contact you shortly to arrange a time and date.",
      p16: "Kind regards,",
      p17: "Dr Orrey",
    };

    setForm(defaultForm);
    //setPreviewData(defaultForm);
    props.emailPreviewData(defaultForm);
  }, []);

  function breakline(event) {
    if (event.key === "Enter") {
      document.execCommand("insertLineBreak");
      event.preventDefault();
    }
  }

  function updatePreviewData(event) {
    //console.log("event: ", event);
    //console.log(
    //   "event.target.getAttribute('data-p'): ",
    //   event.target.getAttribute("data-p")
    // );
    //console.log("event.target.classList.value: ", event.target.classList.value);
    //console.log("event.target.innerHTML: ", event.target.innerHTML);

    form[event.target.getAttribute("data-p")] = event.target.innerHTML;
    //console.log("form: ", form);
    //setPreviewData(content);
    props.emailPreviewData(form);
  }

  return (
    <>
      {form !== null && (
        <S.Main onInput={(e) => updatePreviewData(e)}>
          <S.P>
            <div data-p="p1" {...editableContent}>
              {form.p1}
            </div>
            {requestData.clients["name"]}
          </S.P>
          <br />
          <S.P>
            <div data-p="p2" {...editableContent}>
              {form.p2}
            </div>
          </S.P>

          <S.P>
            <S.Half>
              <div data-p="p3" {...editableContent}>
                {form.p3}
              </div>
              <S.Output>{formData["diagnose__diagnosis"]}</S.Output>
            </S.Half>
            <S.Half>
              <div data-p="p4" {...editableContent}>
                {form.p4}
              </div>
              <S.Output>{formData["diagnose__medcode"]}</S.Output>
            </S.Half>
          </S.P>

          {/* {formData["diagnose__popappoint"] ? (
            <S.Block>
              <S.Contents data-p="p11" {...editableContent}>
                {form.p11}
              </S.Contents>

              <S.Contents data-p="p12" {...editableContent}>
                {form.p12}
              </S.Contents>
            </S.Block>
          ) : (
            <S.P data-p="p13" {...editableContent}>
              {form.p13}sdfbfd
            </S.P>
          )} */}

          {/* //appointment date */}

          {formData["diagnose__followupapp"] ? (
            <>
              <S.P>
                <div data-p="p14" {...editableContent}>
                  {form.p14}<S.Output>{formData["diagnose__appointmenttype"]}</S.Output>. {form.p15}
                </div>
              </S.P>
            </>
          ) : (
            <S.P data-p="p15" {...editableContent}>

            </S.P>
          )}

          {formData["scriptgroup__script"] ? (
            <>
              <S.P data-p="p5" {...editableContent}>
                {form.p5}
              </S.P>
              <S.P>
                <S.Output>{formData["scriptgroup__name"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__type"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__dosage"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__when"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__howManyDays"]} { formData["scriptgroup__howManyDays"] ? 'days' : ''} </S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__numRepeats"]}</S.Output>
                <div data-p="p6" {...editableContent}>
                  {form.p6}
                </div>
              </S.P>
            </>
          ) : (
            <S.P data-p="p7">{form.p7}</S.P>
          )}

          {formData["addscriptgroup__addScript"] ? (
            <>
              <S.P data-p="p8" {...editableContent}>
                {form.p8}
              </S.P>
              <S.P>
                <S.Output>{formData["addscriptgroup__name"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__type"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__dosage"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__when"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__howManyDays"]} { formData["addscriptgroup__howManyDays"] ? 'days' : ''} </S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__numRepeats"]}</S.Output>
                <div data-p="p9" {...editableContent}>
                  {form.p9}
                </div>
              </S.P>
            </>
          ) : (
            <S.P data-p="p10" {...editableContent}>
              {form.p10}
            </S.P>
          )}

          {formData["addnotesgroup__addNotes"] && (
            <S.P>{formData["addnotesgroup__addNotes"]}</S.P>
          )}
          <br />
          <S.P data-p="p16" {...editableContent}>
            {form.p16}
          </S.P>

          <S.P data-p="p17" {...editableContent}>
            {form.p17}
          </S.P>
        </S.Main>
      )}
    </>
  );
};

export default RashEmailPreview;
