import styled from "@emotion/styled";

export const SectionHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: ${(props) => (props.padding ? props.padding : "100px 0 20px 0")};
`;

export const SectionContent = styled.div`
  width: 85%;
  margin: 0 auto;
  padding: 40px 10px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #00000060;
  /* padding: 10px 10px 10px 0; */
  padding: 6px 10px 6px 0;
`;

export const Field = styled.div`
  font-size: 16px;
`;
