import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Button from "../../Base/Button/Button";

export const List = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  border-radius: 10px;
`;

export const ListType = styled.div`
  width: 100%;
  background: #0594a9;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
`;

export const TableList = styled.div`
  width: 100%;
  box-shadow: 0px 2px 25px #00000033;
  border-radius: 0px 0px 10px 10px;
`;

export const Row = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  color: #000;
  cursor: pointer;
  background: ${(props) => (props.background ? props.background : "#FFF")};

  :hover {
    background: #0594a9;
    color: #fff;
  }
`;

export const Header = styled(Row)`
  width: auto;
  display: flex;
  font-size: 14px;
  justify-content: left;
  :hover {
    background: #fff;
  }
`;

export const HeaderField = styled.div`
  color: #0594a9;
  width: 100%;
  display: flex;
  justify-content: left;
  padding:3px;
  font-size: 15px;
`;

export const RowList = styled.div`
  width: 100%
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  cursor: pointer;
  font-size: 14px;
  padding:3px
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const DeleteBtn = styled(Button)`
  margin: 0px 10px;
  background: #c91a1a;
`;

export const DownloadBtn = styled(Button)`
  margin: 0px 10px;
  background: #c91a1a;
`;

export const Btn = styled(Button)`
  margin: 0px 10px;
`;
