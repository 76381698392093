const TablesObject = () => {
  return {
    "rash-table": {
      details: {
        id: "ID",
        caption: "{1} :rashescount rash total|[2,*] :rashescount total rashes",
        created: "Created",
        updated: "Updated",
        actions: "Actions",
      },
      client: {
        name: "Name",
        id_number: "ID Number",
        email: "Email",
        phone: "Phone",
        age: "Date Of Birth",
        sex: "Gender",
        street_address: "Street Address",
        medical_aid_number: "Medical Aid Number",
        medical_aid_name: "Medical Aid Name",
        principle_member_id_number: "Principal Member ID Number",
        principle_member_name: "Principal Memeber Name",
      },
      info: {
        doctor: "Referring Doctor",
        first_appear: "First appeared",
        start_with_lesion: "Start With lesion?",
        itchy: "Itchy?",
        affected_limb: "Affected area",
        has_spread: "Spread?",
        spread_to: "Spread to",
        has_contagion: "Come into contact with?",
        contagion_item: "Contagion",
        comment: "Comment",
      },
      med: {
        previous_treatment: "Previous treatment",
        on_medication: "On any medication?",
        medication: "Medication",
        changed_medication: "Recently changed meds",
        medication_change_from: "Changed from",
        uses_creams: "Uses creams?",
        creams: "Creams",
        wash_with: "Washes with",
        medical_conditions: "Medical conditions",
        elaborate: "Elaborate",
        takes_supplements: "Takes supplements?",
        supplements: "Supplements?",
      },
    },
    "spot-table": {
      details: {
        id: "ID",
        caption: "{1} :spotscount spot total|[2,*] :spotscount total spots",
        created: "Created",
        updated: "Updated",
        actions: "Actions",
      },
      client: {
        name: "Name",
        id_number: "ID Number",
        email: "Email",
        phone: "Phone",
        age: "Date Of Birth",
        sex: "Gender",
        street_address: "Street Address",
        medical_aid_number: "Medical Aid Number",
        medical_aid_name: "Medical Aid Name",
        principle_member_id_number: "Principal Member ID Number",
        principle_member_name: "Principal Memeber Name",
      },
      info: {
        doctor: "Referring Doctor",
        first_appear: "First Appeared",
        affected_limb: "Affected limb",
        rapid: "Spread rapidly?",
        lesion_colour: "Colour of lesion",
        colour_changed: "Colour changed?",
        colour_changed_to: "Colour change to",
        symptom: "Symptom",
        tumours_removed: "Any tumours removed?",
        tumours_removed_from: "Tumours removed from",
        tumours_removed_when: "Tumours removed when",
        which_cancer: "Which Cancer?",
        fam_cancer: "Family cancer history?",
        which_fam_cancer: "Family cancer type",
        who_fam_cancer: "Who in family had this cancer",
        comment: "Patient comment",
      },
      med: {
        medication: "Medication",
        conditions: "Medical Conditions",
      },
    },
    "acne-table": {
      details: {
        id: "ID",
        caption: "{1} :acnescount acne total|[2,*] :acnescount total acnes",
        created: "Created",
        updated: "Updated",
        actions: "Actions",
      },
      client: {
        name: "Name",
        id_number: "ID Number",
        email: "Email",
        phone: "Phone",
        age: "Date Of Birth",
        sex: "Gender",
        street_address: "Street Address",
        medical_aid_number: "Medical Aid Number",
        medical_aid_name: "Medical Aid Name",
        principle_member_id_number: "Principal Member ID Number",
        principle_member_name: "Principal Memeber Name",
      },
      info: {
        doctor: "Referring Doctor",
        how_long: "Acne for how long?",
        affected_limb: "Affected bodypart",
        affected_limb_other: "Affected bodypart Other",
        acne_history: "Acne history",
        adverse_reactions: "Any adverse reactions",
        family: "Family history",
        conditions: "Any medical conditions?",
        period_age: "Period Age",
        regular_menstrual: "Regular Cycle?",
        perimenstrual: "Perimenstrual?",
        facial_hair: "Any facial hair?",
        excess_hair: "Any excess hair?",
        pigment_marks: "Any pigment marks?",
        pigmentation: "Pigmentation",
        thrombosis: "Thrombosis?",
        recommendation: "Wants products recommended?",
        which_products: "Which Products",
        comment: "Comment",
      },
      med: {
        prescribed: "Prescribed meds?",
        prescribed_meds: "Prescribed Meds",
        regular_medication: "Regular medication?",
        meds_effective: "Meds effective?",
        non_acne_meds: "Other Meds?",
        medication: "Which meds",
        takes_supplements: "Supplements?",
        supplements: "Which supplements",
        on_contraception: "Is on contraception?",
        contraceptive: "The contraceptive",
        contraceptive_length: "How long on the contraceptive",
        how_often: "How often?",
        elaborate: "Medical conditions",
      },
    },
  };
};

export default TablesObject;
