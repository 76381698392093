import styled from "@emotion/styled";
import * as T from "../../../theme/quickspot-theme";

export const Btn = styled.button`
  font-size: ${T.$fontSize ? T.$fontSize : "20px"};
  line-height: ${T.$lineHeight ? T.$lineHeight : "20px"};
  padding: 10px 40px;
  border-radius: ${T.$borderRadius ? T.$borderRadius : "20px"};
  background: ${T.$primaryColor ? T.$primaryColor : "#0594a9"};
  color: ${T.$primaryHighlightTextColor
    ? T.$primaryHighlightTextColor
    : "#fff"};
  text-decoration: none;
  border: 0px;
  box-shadow: 0px 1px 5px #5c5c5c;

  &.critical {
    background: #c91a1a;
  }

  &.warning {
    background: #ee902f;
  }

  &:hover,
  &.active {
    background: ${T.$primaryHighlightColor
      ? T.$primaryHighlightColor
      : "#ee902f"};
  }
`;
