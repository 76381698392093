import React, { Fragment } from "react";
import * as S from "./DetailList.styles";
import { nanoid } from "nanoid";

/**
 * @name DetailList
 * @description Displays records whose fields are grouped under a header
 * @param {array} data Contains array of objects with 2 properties: 'header' and 'data'.
 * @param {array} data.data Contains array of objects with 2 properties: 'text' and 'value'.
 * Each data.data array is a individual record.
 */
const DetailList = (props) => {
  const data = props.data;
  // console.log('custom data1 ; ', data)
  return (
    <>
      {data.map((dataObject, dataIndex) => (
        <Fragment key={dataIndex}>
          <S.SectionHeader padding="40px 0 20px 0">
            {dataObject.header}
          </S.SectionHeader>
          <S.SectionContent>
            {dataObject.data.map((object) => (
              <S.Row key={nanoid()}>
                <S.Field>{object.text}</S.Field>
                <S.Field>{object.value}</S.Field>
              </S.Row>
            ))}
          </S.SectionContent>
        </Fragment>
      ))}
    </>
  );
};

export default DetailList;
