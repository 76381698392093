import React, { useState, useEffect } from "react";
import CornerLogo from "../../components/Simple/CornerLogo/CornerLogo";
import SidebarProfile from "../../components/Compound/SidebarProfile/SidebarProfile";
import Footer from "../../components/Simple/Footer/Footer";
import * as S from "./Patients.styles";
import SimpleList from "../../components/Simple/SimpleList/SimpleList";

import axios from "axios";

const Dashboard = () => {
  const [patientList, setPatientList] = useState([]);

  const listHeaders = [
    { field: "id", text: "ID", width: 1 },
    { field: "name", text: "Name", width: 3 },
    { field: "email", text: "Email", width: 3 },
    { field: "phone", text: "Phone", width: 3 },
    { text: "Actions", width: 1 },
  ];

  const [listParams, setListParams] = useState({});

  useEffect(() => {
    getPatients();
  }, []);

  const getPatients = async (e) => {
    try {
      var patients;
      if (e) {
        if (e.key === "Enter") {
          e.preventDefault();
          /* Moving this query outside of the enter press check will 
          cause the search result to show as you type */
          patients = await axios.get(
            `${process.env.REACT_APP_REACT_APP_APIURL}/clients/${e.currentTarget.value}`
          );
        } else {
          return;
        }
      } else {
        patients = await axios.get(
          `${process.env.REACT_APP_REACT_APP_APIURL}/clients`
        );
      }

      //console.log("Test: ", patients);
      setListParams({
        name: "Patients",
        headers: listHeaders,
        data: patients,
      });
      setPatientList(patients);
    } catch (error) {
      alert(error);
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  return (
    <S.Page>
      <CornerLogo />
      <S.MainContentContainer>
        <SidebarProfile />
        <S.ListContainer>
          <S.SearchContainer>
            <S.SearchIcon />
            <S.Search placeholder="Search" onKeyDown={getPatients} />
          </S.SearchContainer>

          {patientList.length != 0 ? <SimpleList params={listParams} /> : null}
        </S.ListContainer>
      </S.MainContentContainer>
      <Footer />
    </S.Page>
  );
};

export default Dashboard;
