const SpotEmailFormConfig = {
    id: 1,
    headerLeft: "Form 12 - Dave Samuels",
    headerRight: "Diagnosis",
    displayHeader: true,
    fieldGroups: [
        {
            header: "Diagnose",
            type: "field",
            groupName: "diagnose",
            fields: [
                {
                    inputType: "text",
                    label: "Diagnosis",
                    fieldName: "diagnosis",
                },
                {
                    inputType: 'select',
                    label: 'IDC-10 Code',
                    fieldName: 'medcode',
                    options: [
                        { text: 'B00.9- Herpesviral infection, unspecified', value: 'B00.9'},
                        { text: 'B01.9- Varicella without complication', value: 'B01.9'},
                        { text: 'B07- Viral warts', value: 'B07'},
                        { text: 'C44.3- Malignant neoplasm, skin of other and unspecified parts of the face', value: 'C44.3'},
                        { text: 'C44.7- Malignant neoplasm, skin of lower limb, including hip', value: 'C44.7'},
                        { text: 'D18.0- Benign neoplasm: Haemangioma, any site', value: 'D18.0'},
                        { text: 'D22.4- Melanocytic nevi of scalp and neck', value: 'D22.4'},
                        { text: 'D22.5- Melanocytic naevi of trunk', value: 'D22.5'},
                        { text: 'D22.9- Melanocytic nevi, unspecified', value: 'D22.9'},
                        { text: 'D23.9- Benign neoplasm: skin. Unspecified', value: 'D23.9'},
                        { text: 'L20.9- Atopic dermatitis, unspecified.', value: 'L20.9'},
                        { text: 'L23.9- Allergic contact dermatitis, unspecified cause', value: 'L23.9'},
                        { text: 'L24.9- Irritant contact dermatitis, unspecified cause', value: 'L24.9'},
                        { text: 'L27.0- Generalized skin eruption due to drugs and medicaments.', value: 'L27.0'},
                        { text: 'L30.9- Dermatitis, unspecified', value: 'L30.9'},
                        { text: 'L40.0- Psoriasis vulgaris', value: 'L40.0'},
                        { text: 'L50.9- Urticaria unspecified', value: 'L50.9'},
                        { text: 'L57.0- Actinic keratosis', value: 'L57.0'},
                        { text: 'L70.0- Acne vulgaris', value: 'L70.0'},
                        { text: 'LL71.0- Perioral dermatitis', value: 'L71.0'},
                        { text: 'L71.9- Rosacea, unspecified', value: 'L71.9'},
                        { text: 'L72.0- Epidermal cyst', value: 'L72.0'},
                        { text: 'L82- Seborrheic keratosis', value: 'L82'}

                    ]
                },
                {
                    inputType: "text",
                    label: "Other IDC-10 Code",
                    fieldName: "medcode",
                },
                // {
                //   inputType: "text",
                //   label: "IDC-10 Code",
                //   fieldName: "medcode",
                // },
                {
                    inputType: "radio",
                    label: "Cancerous",
                    fieldName: "cancerous",
                    default: "Benign",
                    buttonOptions: [
                        {
                            label: "Benign",
                            value: "Benign",
                        },
                        {
                            label: "Malignent",
                            value: "Malignent",
                        },
                        {
                            label: "Precancerous",
                            value: "Precancerous",
                        },
                    ],
                },
                {
                    inputType: "radio",
                    label: "Removal of spot?",
                    fieldName: "removespot",
                    default: 1,
                    buttonOptions: [
                        {
                            label: "Yes",
                            value: 1,
                        },
                        {
                            label: "No",
                            value: 0,
                        },
                    ],
                },
                {
                    inputType: "select",
                    label: "Type of removal",
                    fieldName: "typeremoval",
                    options: [
                        { text: "Shave Excision", value: "Shave Excision" },
                        { text: "Cautery", value: "Cautery" },
                        { text: "Cryotherapy", value: "Cryotherapy" },
                        { text: "Curettage", value: "Curettage" },
                        { text: "Liquid Nitrogen", value: "Liquid Nitrogen" },
                    ],
                },
                // {
                //   inputType: "radio",
                //   label: "Biopsy",
                //   fieldName: "biopsy",
                //   default: 1,
                //   buttonOptions: [
                //     {
                //       label: "Yes",
                //       value: 1,
                //     },
                //     {
                //       label: "No",
                //       value: 0,
                //     },
                //   ],
                // },
                {
                    inputType: "radio",
                    label: "Follow Up appointment",
                    fieldName: "popappoint",
                    default: 1,
                    buttonOptions: [
                        {
                            label: "Yes",
                            value: 1,
                        },
                        {
                            label: "No",
                            value: 0,
                        },
                    ],
                },
                {
                    inputType: "select",
                    label: "Appointment Type",
                    fieldName: "appointmenttype",
                    options: [
                        { text: "Short pop-in appointment", value: "short pop-in appointment" },
                        { text: "Long pop-in appointment", value: "long pop-in appointment" },
                        { text: "Urgent appointment", value: "urgent appointment" },
                        { text: "Follow up appointment", value: "follow up appointment" },
                    ],
                },
                // {
                //   inputType: "text",
                //   label: "Approx. Cost",
                //   fieldName: "approxcost",
                // },
                // {
                //   inputType: "radio",
                //   label: "Follow up appointment",
                //   fieldName: "followupapp",
                //   default: 1,
                //   buttonOptions: [
                //     {
                //       label: "Yes",
                //       value: 1,
                //     },
                //     {
                //       label: "No",
                //       value: 0,
                //     },
                //   ],
                // },
                // {
                //   inputType: "datetime",
                //   label: "When",
                //   fieldName: "when",
                // },
                // {
                //   inputType: "text",
                //   label: "Reason",
                //   fieldName: "reason",
                // },
            ],
        },
        {
            header: "Script",
            type: "field",
            groupName: "scriptgroup",
            fields: [
                {
                    inputType: "radio",
                    label: "Script",
                    fieldName: "script",
                    default: 1,
                    buttonOptions: [
                        {
                            label: "Yes",
                            value: 0,
                        },
                        {
                            label: "No",
                            value: 1,
                        },
                    ],
                },
                {
                    inputType: "text",
                    label: "Name",
                    fieldName: "name",
                },
                {
                    inputType: "select",
                    label: "Type",
                    fieldName: "type",
                    options: [
                        { text: "Caps", value: "Caps" },
                        { text: "Lotion", value: "Lotion" },
                        { text: "Cream", value: "Cream" },
                        { text: "Tablets", value: "Tablets" },
                    ],
                },
                {
                    inputType: "select",
                    label: "Dosage",
                    fieldName: "dosage",
                    options: [
                        { text: "1 x daily", value: "1 x daily" },
                        { text: "2 x daily", value: "2 x daily" },
                        { text: "3 x daily", value: "3 x daily" },
                        { text: "4 x daily", value: "4 x daily" },
                    ],
                },
                {
                    inputType: "select",
                    label: "When",
                    fieldName: "when",
                    options: [
                        { text: "mornings", value: "mornings" },
                        { text: "afternoon", value: "afternoon" },
                        { text: "evenings", value: "evenings" },
                        { text: "mornings and afternoon", value: "mornings and afternoon" },
                        { text: "mornings and evenings", value: "mornings and evenings" },
                        { text: "afternoon and evenings", value: "afternoon and evenings" },
                        { text: "every 4 hours", value: "every 4 hours" },
                    ],
                },
                {
                    inputType: "text",
                    label: "How many days",
                    fieldName: "howManyDays",
                },
                {
                    inputType: "select",
                    label: "Number of Repeats",
                    fieldName: "numRepeats",
                    options: [
                        { text: "0", value: "0" },
                        { text: "1", value: "1" },
                        { text: "2", value: "2" },
                        { text: "3", value: "3" },
                        { text: "4", value: "4" },
                        { text: "5", value: "5" },
                        { text: "6", value: "6" },
                    ],
                },
            ],
        },
        {
            header: "Additional Notes",
            type: "field",
            groupName: "addnotesgroup",
            fields: [
                {
                    inputType: "radio",
                    label: "Alternative Recommendations",
                    fieldName: "altrec",
                    default: 1,
                    buttonOptions: [
                        {
                            label: "Yes",
                            value: 1,
                        },
                        {
                            label: "No",
                            value: 0,
                        },
                    ],
                },
                // {
                //     inputType: "textarea",
                //     label: "Elaborate",
                //     fieldName: "elaborate",
                // },
                {
                    inputType: "textarea",
                    label: "Additional Notes",
                    fieldName: "addNotes",
                },
            ],
        },
    ],
};

export default SpotEmailFormConfig;
