import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Modal = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000040;
  overflow: auto; /* Enable scroll if needed */
`;

export const ModalDisplay = styled.div`
  height: auto;
  width: ${(props) => (props.width ? props.width : "60%")};
  background: #fff;
  position: absolute;
  /* top: 50%; absolutely center feels off center*/
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  /* min-height: 200px; */
  min-width: 300px;
`;

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: inherit;
  align-items: inherit;
  height: 75vh;
  justify-content: space-evenly;
  width: 100%;
`;

export const BtnLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

export const ModalBtn = styled.div`
  bottom: 0px;
  margin: 20px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
