import React, { useState } from "react";
import * as S from "./SimpleList.styles";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import axios from "axios"; // Import css
// import Modal from 'react-modal';
import Modal from "../../Simple/Modal/Modal";
import DetailList from "../../Simple/DetailList/DetailList";

const SimpleList = (props) => {
  const navigate = useNavigate();
  //console.log("props: ", props);
  const styleObjAction = {
    justifyContent: 'center',
    cursor: 'pointer',
  }

  const [orderListField, setOrderListField] = useState(false);
  const [orderListStatus, setOrderListStatus] = useState(0);

  const listNavigateOnClick = props.params.navigate
    ? props.params.navigate
    : "";
  const listName = props.params.name ? props.params.name : "";

  const data = {
    headers: props.params.headers,
    rows: props.params.data.data,
  };

  let colWidth = [];
  let width = 0;
  let isPopoverOpen = false;

  for (let i = 0; i < data.headers.length; i++) {
    if (data.headers[i].hasOwnProperty("width")) {
      width += data.headers[i].width;
      colWidth.push(data.headers[i].width);
    } else {
      width += 1;
      colWidth.push(1);
    }
  }

  for (let i = 0; i < colWidth.length; i++) {
    colWidth[i] = (100 / width) * colWidth[i];
  }

  if (orderListField && orderListStatus !== 0) {
    //console.log("orderListField: ", orderListField);
    data.rows.sort(sortRequests);
  }

  function openRequestDiagnosis(requestId) {
    //console.log("value: ", requestId);
    if (listNavigateOnClick) {
      navigate(listNavigateOnClick, {
        state: {
          requestId: requestId,
        },
      });
    }
  }

  function orderRequests(field) {
    if (orderListStatus + 1 > 2) {
      setOrderListStatus(1);
    } else {
      setOrderListStatus(orderListStatus + 1);
    }

    setOrderListField(field);
  }

  function sortRequests(a, b) {
    const A = a[orderListField];
    const B = b[orderListField];

    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }

    if (orderListStatus == 2) {
      return comparison * -1;
    }

    return comparison;
  }

  async function deleteClientRecord(clientID) {
    await axios.post("https://vm730ndav.quickspot.online/updateClientDeleteStatus", {
      id: clientID
    }).then((response) => {
      console.log(response.data);
      navigate(0);
    }).catch(error => {
      console.log('error : ', error);
    });
  }

  function CancelRequest() {
    return false;
  }

  function confirmDelete(recordID) {
    confirmAlert({
      title: 'Confirm to Delete Patient Record',
      message: 'Are you sure to do delete Patient record' +
          '' +
          '.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteClientRecord(recordID)
        },
        {
          label: 'No',
          onClick: () => CancelRequest()
        }
      ]
    });
  }

  let CurrentProfile = useState({});
  let [clientInfo, setSetClientInfo] = useState('');


  function showClientProfile(clientProfile) {
    console.log(clientProfile)
    let clientInfo = [];
    if(clientProfile.has_medical_aid === 'Yes') {
      clientInfo = [
        {text: 'Name', value: clientProfile.name || 'Not Availabe'},
        {text: 'ID Number', value: clientProfile.id_number || 'Not Availabe'},
        {text: 'Email Address', value: clientProfile.email || 'Not Availabe'},
        {text: 'Phone', value: clientProfile.phone || 'Not Availabe'},
        {text: 'Age', value: clientProfile.age || 'Not Availabe'},
        {text: 'Sex', value: clientProfile.sex || 'Not Availabe'},
        {text: 'Street Address', value: clientProfile.street_address || 'Not Availabe'},
        {text: 'Refering Doctor', value: clientProfile.referring_doctor || 'Not Availabe'},
        {text: 'Has Medical Aid', value: clientProfile.has_medical_aid || 'Not Availabe'},
        {text: 'Medical Aid Number', value: clientProfile.medical_aid_number || 'Not Availabe'},
        {text: 'Medical Aid Name', value: clientProfile.medical_aid_name || 'Not Availabe'},
        {text: 'Principal Member ID Number', value: clientProfile.principle_member_id_number || 'Not Availabe'},
        {text: 'Principal Memeber Name', value: clientProfile.principle_member_name || 'Not Availabe'},
      ]
    } else {
      clientInfo = [
        {text: 'Name', value: clientProfile.name},
        {text: 'ID Number', value: clientProfile.id_number || 'Not Availabe'},
        {text: 'Email Address', value: clientProfile.email || 'Not Availabe'},
        {text: 'Phone', value: clientProfile.phone || 'Not Availabe'},
        {text: 'Age', value: clientProfile.age || 'Not Availabe'},
        {text: 'Sex', value: clientProfile.sex || 'Not Availabe'},
        {text: 'Street Address', value: clientProfile.street_address || 'Not Availabe'},
        {text: 'Refering Doctor', value: clientProfile.referring_doctor || 'Not Availabe'}
      ]
    }

    setSetClientInfo([{ header: "Patient Details", data: clientInfo }]);
    openModal()
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function detailsModal() {
    return (
      <>
             <Modal

properties={{
  height: "100%",
  borderRadius: "20px",
  displayHeader: false,
  width: "100%",
}}

cancelBtn={{
  label: "Close",
  exitModal: true,
  callbackHandler: () => {
    closeModal()
  },
}}

>
{clientInfo && <DetailList data={clientInfo} />}
</Modal>
      </>
    );
  }

  return (
    <>

      <S.List>
        <S.ListType>{listName}</S.ListType>
        <S.TableList>
          <S.Header>
            {data.headers.map((object, index) => (
              <S.HeaderField
                key={index}
                width={colWidth[index] + "%"}
                onClick={() => orderRequests(object.field)}
              >
                {object.text}
              </S.HeaderField>
            ))}
          </S.Header>

          <S.RowList>
            {data.rows.map((rowArray, rowIndex) => (
              <S.Row
                key={rowIndex}
                onClick={() => openRequestDiagnosis(rowArray.id)}
                background={
                  rowArray.diagnosis_status !== "diagnosed" ? "#0ef" : "#78c162"
                }
              >
                {Object.keys(rowArray).map((fieldObject, fieldIndex) => (

                    fieldIndex < 4
                     ? <S.Field width={fieldIndex == 0 ? '10%' : '25%'} key={fieldIndex} >
                        {rowArray[fieldObject]}
                      </S.Field>
                        : null
                  ))}
                  <S.Field key={rowIndex+'_ca_col'} width={'20%'}>
                    <S.Btn onClick={() =>showClientProfile(rowArray)}>
                    View
                      </S.Btn>
                    <S.DeleteBtn onClick={() =>confirmDelete(rowArray.id)}>
                        Delete
                      </S.DeleteBtn>
                  </S.Field>
              </S.Row>
            ))}
          </S.RowList>
        </S.TableList>

      </S.List>
      {modalIsOpen && detailsModal()}
    </>
  );
};

export default SimpleList;
