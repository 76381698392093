import React, { useState } from "react";
import * as S from "./SimpleRequestList.styles";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from "axios"; // Import css

import { Oval } from 'react-loader-spinner'

const SimpleRequestList = (props) => {
  const navigate = useNavigate();
  //console.log("props: ", props);
  const styleObjAction = {
    justifyContent: 'left',
    cursor: 'pointer',
  }

  const [orderListField, setOrderListField] = useState(false);
  const [orderListStatus, setOrderListStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const listNavigateOnClick = props.params.navigate
    ? props.params.navigate
    : "";
  const listName = props.params.name ? props.params.name : "";

  const data = {
    headers: props.params.headers,
    rows: props.params.data.data,
  };

  let colWidth = [];
  let width = 0;

  for (let i = 0; i < data.headers.length; i++) {
    if (data.headers[i].hasOwnProperty("width")) {
      width += data.headers[i].width;
      colWidth.push(data.headers[i].width);
    } else {
      width += 1;
      colWidth.push(1);
    }
  }

  for (let i = 0; i < colWidth.length; i++) {
    colWidth[i] = (100 / width) * colWidth[i];
  }

  if (orderListField && orderListStatus !== 0) {
    //console.log("orderListField: ", orderListField);
    data.rows.sort(sortRequests);
  }

  function openRequestDiagnosis(requestId) {
    //console.log("value: ", requestId);
    if (listNavigateOnClick) {
      navigate(listNavigateOnClick, {
        state: {
          requestId: requestId,
        },
      });
    }
  }

  function orderRequests(field) {
    if (orderListStatus + 1 > 2) {
      setOrderListStatus(1);
    } else {
      setOrderListStatus(orderListStatus + 1);
    }

    setOrderListField(field);
  }

  function sortRequests(a, b) {
    const A = a[orderListField];
    const B = b[orderListField];

    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }

    if (orderListStatus == 2) {
      return comparison * -1;
    }

    return comparison;
  }

  async function deleteRequestRecord(recordID) {
    await axios.post("https://vm730ndav.quickspot.online/updateRequestDeleteStatus", {
      id: recordID
    }).then((response) => {
      console.log(response.data);
      navigate(0);
    }).catch(error => {
      console.log('error : ', error);
    });
  }

  async function downloadRecordPdf(recordID, clientName) {
    setIsLoading(true);
    await axios.post(`${process.env.REACT_APP_REACT_APP_APIURL}/generateClientReport`, {
      id: recordID
    }, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const fileName = clientName.replace(/\s+/g, " ").replace(/\s/g, "_").toLowerCase();
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = `${fileName}.pdf`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error : ', error);
      });
  }

  function CancelRequest() {
    return false;
  }

  function confirmDelete(recordID) {
    confirmAlert({
      title: 'Confirm to Delete Request',
      message: 'Are you sure to do delete Request.',
      buttons: [
        {

          label: 'Yes',
          onClick: () => deleteRequestRecord(recordID)
        },
        {
          label: 'No',
          onClick: () => CancelRequest()
        }
      ]
    });
  }

  async function markAsComplete(recordID) {
    console.log("Complete: ", recordID)
    try {
      await axios.post(
          `${process.env.REACT_APP_REACT_APP_APIURL}/updateDiagnosisStatus`,
          {
            id: recordID,
            type: "diagnosed",
            emailData: {completed: true},
          }
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.data.msg);
      }

    }
  }

  return (
    isLoading ?
      <Oval
        height={80}
        width={80}
        color="#fff"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#1ecbe1"
        strokeWidth={2}
        strokeWidthSecondary={2}

      />
      :
      <>
        <S.List>
          <S.ListType>{listName}</S.ListType>
          <S.TableList>
            <S.Header>
              {data.headers.map((object, index) => (
                <S.HeaderField
                  key={index}
                  width={colWidth[index] + "%"}
                  onClick={() => orderRequests(object.field)}
                >
                  {object.text}
                </S.HeaderField>
              ))}
            </S.Header>

            <S.RowList>
              {data.rows.map((rowArray, rowIndex) => (
                <S.Row
                  key={rowIndex}

                  background={
                    rowArray.diagnosis_status !== "diagnosed" ? "#0ef" : "#78c162"
                  }
                >

                  {Object.keys(rowArray).map((fieldObject, fieldIndex) => (
                    fieldIndex < 5
                      ? <S.Field width={fieldIndex == 1 ? '10' : '20' + "%"} onClick={() => openRequestDiagnosis(rowArray.id)} key={fieldIndex} >{rowArray[fieldObject]}</S.Field>
                      : <S.Field width={"10%"} key={rowIndex + '_ra_col'} >
                        <S.DownloadBtn onClick={() => downloadRecordPdf(rowArray.id, rowArray.clientName)}>
                          Download
                        </S.DownloadBtn>
                          <S.CompleteBtn onClick={() => markAsComplete(rowArray.id)}>
                            Complete
                          </S.CompleteBtn>
                        <S.DeleteBtn onClick={() => confirmDelete(rowArray.id)}>
                          Delete
                        </S.DeleteBtn>
                      </S.Field>
                  ))}
                </S.Row>
              ))}
            </S.RowList>
          </S.TableList>
        </S.List>
      </>


  );
};

export default SimpleRequestList;
