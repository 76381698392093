import styled from "@emotion/styled";

export const Page = styled.div`
  height: 100vh;
  flex-flow: column;
  display: flex;
`;

export const MainContentContainer = styled.div`
  /* width: 70%; */
  width: 80%;
  display: flex;
  margin: 0 auto;
  flex: 1 1 auto;
`;
