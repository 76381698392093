import styled from "@emotion/styled";
import logo from "../../../images/newLogo.jpg";

export const LogoContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "unset")};
  display: flex;
  flex: 0 1 auto;
`;

export const Logo = styled.div`
  width: ${(props) => (props.width ? props.width : "200px")};
  height: ${(props) => (props.height ? props.height : "130px")};
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: ${(props) => (props.margin ? props.margin : "20px 120px")};
`;
