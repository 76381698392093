import styled from "@emotion/styled";
import logo from "../../../images/newLogo.jpg";

export const LogoContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
`;

export const Logo = styled.div`
  width: 200px;
  height: 130px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 20px 120px;
`;

export const Footer = styled.div`
  width: 100%;
  /* height:5vh; */
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  bottom: 0px;
  font-size: 20px;
  line-height: 26px;
  flex: 0 1 auto;
`;

export const FooterText = styled.p`
  color: #000;
`;
