import React, { useState, useEffect } from "react";
import * as S from "./EmailPreview.styles";

const SpotEmailPreview = (props) => {
  const formData = props.formData;
  const requestData = props.requestData;

  const [previewData, setPreviewData] = useState("");
  const [form, setForm] = useState(null);

  const editableContent = {
    contentEditable: true,
    suppressContentEditableWarning: true,
    onKeyDown: breakline,
  };

  useEffect(() => {
    const defaultForm = {
      p1: "Dear ",
      p2: "Please see the results of your Quick Spot diagnosis below: ",
      p3: "Diagnosis: ",
      p4: "Medical Aid Code: ",
      p5: "Your spot is ",
      p6: " and it ",
      p7: "needs to be removed by ",
      p8: "Your spot is ",
      p9: " and it does not need to be removed. ",
      p10: "A biopsy is needed. ",
      p11: "No biopsy is needed. ",
      p12: "Dr Orrey recommends a ",
      p13: "We will contact you shortly to arrange a time and date.",
      p14: "",
      p15: "Please make a follow up appointment with me in ",
      p16: "",
      p17: "To treat this condition, please see your script attached with the following instructions: ",
      p18: " repeats. ",
      p19: "",
      p20: "",
      p21: "Kind regards,",
      p22: "Dr Orrey",
    };

    setForm(defaultForm);
    props.emailPreviewData(defaultForm);
  }, []);

  function breakline(event) {
    if (event.key === "Enter") {
      document.execCommand("insertLineBreak");
      event.preventDefault();
    }
  }

  function updatePreviewData(event) {
    form[event.target.getAttribute("data-p")] = event.target.innerHTML;
    props.emailPreviewData(form);
  }

  return (
    <>
      {form !== null && (
        <S.Main onInput={(e) => updatePreviewData(e)}>
          <S.P>
            <div data-p="p1" {...editableContent}>
              {form.p1}
            </div>
            {requestData.clients["name"]}
          </S.P>
          <br />
          <S.P>
            <div data-p="p2" {...editableContent}>
              {form.p2}
            </div>
          </S.P>

          <S.P>
            <S.Half>
              <div data-p="p3" {...editableContent}>
                {form.p3}
              </div>
              <S.Output>{formData["diagnose__diagnosis"]}</S.Output>
            </S.Half>
            <S.Half>
              <div data-p="p4" {...editableContent}>
                {form.p4}
              </div>
              <S.Output>{formData["diagnose__medcode"]}</S.Output>
            </S.Half>
          </S.P>

          {formData["diagnose__removespot"] ? (
            <S.P>
              <div data-p="p5" {...editableContent}>
                {form.p5}
              </div>
              <S.Output>{formData["diagnose__cancerous"]}</S.Output>
              <div data-p="p6" {...editableContent}>
                {form.p6}
              </div>
              <div data-p="p7" {...editableContent}>
                {form.p7}
              </div>
              <S.Output>{formData["diagnose__typeremoval"]}</S.Output>.
            </S.P>
          ) : (
            <S.P>
              <div data-p="p8" {...editableContent}>
                {form.p8}
              </div>
              <S.Output>{formData["diagnose__cancerous"]}</S.Output>
              <div data-p="p9" {...editableContent}>
                {form.p9}
              </div>
            </S.P>
          )}

          {/* #TODO - fix biopsy bug where the toggle doesnt work and this text is just displayed */}
          {/* {formData["addscriptgroup__biopsy"] ? (
            <S.P data-p="p10" {...editableContent}>
              {form.p10}ssss
            </S.P>
          ) : (
            <S.P data-p="p11" {...editableContent}>
              {form.p11}
            </S.P>
          )} */}

          {formData["diagnose__popappoint"] ? (
            <S.Block>
              <S.Contents data-p="p12" {...editableContent}>
                {form.p12}<S.Output>{formData["diagnose__appointmenttype"]}</S.Output>. {form.p13}
              </S.Contents>
            </S.Block>
          ) : (
            <S.P data-p="p14" {...editableContent}>
              {/* {form.p14} */}
            </S.P>
          )}

          {/* {formData["diagnose__followupapp"] ? (
            <S.P>
              <div data-p="p15" {...editableContent}>
                {form.p15}
              </div>
              <S.Output>{formData["diagnose__reason"]}</S.Output>
              &nbsp;
              <S.Output>{formData["diagnose__when"]}</S.Output>.
            </S.P>
          ) : (
            <S.P data-p="p16" {...editableContent}>
              {form.p16}
            </S.P>
          )} */}

          {formData["scriptgroup__script"] ? (
            <>
              <S.P data-p="p17" {...editableContent}>
                {form.p17}
              </S.P>
              <S.P>
                <S.Output>{formData["scriptgroup__name"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__type"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__dosage"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__when"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__howManyDays"]} { formData["scriptgroup__howManyDays"] ? 'days' : ''} </S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__numRepeats"]}</S.Output>
                <div data-p="p18" {...editableContent}>
                  {form.p18}
                </div>
              </S.P>
            </>
          ) : (
            <S.P data-p="p19" {...editableContent}>
              {form.p19}
            </S.P>
          )}

          {/* {formData["addnotesgroup__altrec"] ? (
            <S.P>
              <div data-p="p20" {...editableContent}>
                {form.p20}
              </div>
              <S.Output>{formData["addnotesgroup__elaborate"]}</S.Output>
            </S.P>
          ) : (
            <></>
          )} */}

          {formData["addnotesgroup__addNotes"] && (
            <S.P>{formData["addnotesgroup__addNotes"]}</S.P>
          )}
          <br />
          <S.P data-p="p21" {...editableContent}>
            {form.p21}
          </S.P>

          <S.P>
            <div data-p="p22" {...editableContent}>
              {form.p22}
            </div>
          </S.P>
        </S.Main>
      )}
    </>
  );
};

export default SpotEmailPreview;
