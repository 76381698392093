export const $primaryColor = "#0594A9";
export const $primaryTextColor = "#000";
export const $primaryHighlightColor = "#ee902f";
export const $primaryHighlightTextColor = "#fff";

export const $secondaryColor = "#0594A9";
export const $secondaryTextColor = "#FFF";
export const $secondaryHighlightColor = "#ee902f";
export const $secondaryHighlightTextColor = "#fff";

export const $lineHeight = "16px";
export const $fontSize = "16px";
export const $fontFamily = "Roboto, Arial, Helvetica, sans-serif";

export const $border = `1px solid ${$primaryColor}`;
export const $borderRadius = "20px";
export const $fieldBorderRadius = "10px";
