import React from "react";
import * as S from "./Modal.styles";
import Form from "../Form/Form";
import Button from "../../Base/Button/Button";

/**
 * Button properties: label (string), nav (string), exitModal (boolean)
 *  */
const Modal = (props) => {
  const children = props.children;
  const headerLeft = props.headerLeft;
  const headerRight = props.headerRight;
  const properties = props.properties;
  const width = props.width;
  const confirmBtn = props.hasOwnProperty("confirmBtn")
    ? props.confirmBtn
    : false;
  const cancelBtn = props.hasOwnProperty("cancelBtn") ? props.cancelBtn : false;

  function cancelBtnCallback(event) {
    props.cancelBtn.callbackHandler(false);
    event.preventDefault();
  }

  function confirmBtnCallback(event) {
    props.confirmBtn.callbackHandler(false);
    event.preventDefault();
  }

  return (
    <S.Modal>
      <S.ModalDisplay width={width}>
        <Form
          headerLeft={headerLeft}
          headerRight={headerRight}
          properties={properties}
        >
          <S.ModalMain>
            <S.ModalContent>{children}</S.ModalContent>

            <S.ModalBtn>
              {cancelBtn && !cancelBtn.exitModal && (
                <S.BtnLink to={cancelBtn.nav}>
                  <Button>{cancelBtn.label}</Button>
                </S.BtnLink>
              )}
              {cancelBtn && cancelBtn.exitModal && (
                <Button onClick={cancelBtnCallback} background="#c91a1a">
                  {cancelBtn.label}
                </Button>
              )}
              {confirmBtn && !confirmBtn.exitModal && (
                <S.BtnLink to={confirmBtn.nav}>
                  <Button>{confirmBtn.label}</Button>
                </S.BtnLink>
              )}
              {confirmBtn && confirmBtn.exitModal && (
                <Button onClick={confirmBtnCallback}>{confirmBtn.label}</Button>
              )}
            </S.ModalBtn>
          </S.ModalMain>
        </Form>
      </S.ModalDisplay>
    </S.Modal>
  );
};

export default Modal;
