import React, { useState, useEffect } from "react";
import * as S from "./RequestList.styles";
import SimpleRequestList from "../../Simple/SimpleList/SimpleRequestList";

import axios from "axios";

const RequestList = (props) => {
  const listHeaders = [
    { field: "id", text: "ID", width: 1 },
    { field: "clientName", text: "Patient", width: 2 },
    { field: "diagnosis_status", text: "Diagnosis Status", width: 2 },
    { field: "date_requested", text: "Date Requested", width: 2 },
    { field: "date_diagnosed", text: "Date Diagnosed", width: 2 },
    { text: "Action", width: 2 },
  ];

  const [requestList, setRequestList] = useState([]);
  const [listParams, setListParams] = useState({});

  useEffect(() => {
    getRequests();
  }, []);

  const getRequests = async (e) => {
    try {
      var requests;
      if (e) {
        if (e.key === "Enter") {
          e.preventDefault();
          /* Moving this query outside of the enter press check will 
          cause the search result to show as you type */
          requests = await axios.get(
            `${process.env.REACT_APP_REACT_APP_APIURL}/requests/${e.currentTarget.value}`
          );
        } else {
          return;
        }
      } else {
        requests = await axios.get(
          `${process.env.REACT_APP_REACT_APP_APIURL}/requests`
        );
      }

      //console.log("Test: ", requests);
      setListParams({
        name: "Recent Requests",
        headers: listHeaders,
        data: requests,
        navigate: "/emailsetup",
      });
      setRequestList(requests);
    } catch (error) {
      alert(error);
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  return (
    <S.MainContent>
      <S.SearchContainer>
        <S.SearchIcon />
        <S.Search placeholder="Search" onKeyDown={getRequests} />
      </S.SearchContainer>

      {requestList.length != 0 ? (
        <SimpleRequestList params={listParams} />
      ) : null}
    </S.MainContent>
  );
};

export default RequestList;
