import React, { Fragment } from "react";
import * as S from "./FormBuilder.styles";
import Form from "../../Simple/Form/Form";
import DetailList from "../../Simple/DetailList/DetailList";
import RadioButtonGroup from "../../Base/RadioButtonGroup/RadioBtnGroup";
import TextArea from "../../Base/TextArea/TextArea";
import Datetime from "../../Base/Datetime/Datetime";
import SelectList from "../../Base/SelectList/SelectList";
import Text from "../../Base/Text/Text";

const FormBuilder = (props) => {
  const config = props.config;
  const formData = props.formData;
  const noForm = props.noForm ? props.noForm : false;

  function parentCallback(value) {
    //console.log("value: ", value);
    props.parentCallback(value);
  }

  function textField(key, field) {
    return (
      <>
        <S.Label>{field.label}</S.Label>
        <Text
          value={formData[key]}
          type="text"
          onChange={parentCallback}
          name={key}
        />
      </>
    );
  }

  function textareaField(key, field) {
    let cols = 40;
    let rows = 5;

    if (field.hasOwnProperty("cols")) {
      cols = field.cols;
    }

    if (field.hasOwnProperty("rows")) {
      rows = field.rows;
    }

    return (
      <>
        <S.Label>{field.label}</S.Label>
        <TextArea
          cols={cols}
          rows={rows}
          value={formData[key]}
          onChange={parentCallback}
          name={key}
        ></TextArea>
      </>
    );
  }

  function radioField(key, field) {
    var buttonOptions = [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ];

    var defaultVal;
    if (field.hasOwnProperty("default")) {
      defaultVal = field.default;
    } else {
      defaultVal = "Yes";
    }

    if (field.hasOwnProperty("buttonOptions")) {
      buttonOptions = field.buttonOptions;
    }

    return (
      <>
        <S.Label>{field.label}</S.Label>
        <RadioButtonGroup
          buttonOptions={buttonOptions}
          defaultVal={defaultVal}
          value={formData[key]}
          onClick={parentCallback}
          name={key}
        />
      </>
    );
  }

  function selectField(key, field) {
    return (
      <>
        <S.Label>{field.label}</S.Label>
        <SelectList
          options={field.options}
          value={formData[key]}
          onChange={parentCallback}
          name={key}
        />
      </>
    );
  }

  function datetimeField(key, field) {
    return (
      <>
        <S.Label>{field.label}</S.Label>
        <Datetime
          type="date"
          value={formData[key]}
          onChange={parentCallback}
          name={key}
        />
      </>
    );
  }

  function selectFieldToRender(fieldGroup, field) {
    let key = `${fieldGroup.groupName}__${field.fieldName}`;

    var fieldElement;

    switch (field.inputType) {
      case "text":
        fieldElement = textField(key, field);
        break;
      case "textarea":
        fieldElement = textareaField(key, field);
        break;
      case "radio":
        fieldElement = radioField(key, field);
        break;
      case "select":
        fieldElement = selectField(key, field);
        break;
      case "datetime":
        fieldElement = datetimeField(key, field);
        break;
      default:
        fieldElement = textField(key, field);
        break;
    }

    return <>{fieldElement}</>;
  }

  function DetailListGroup(fieldGroup) {
    return (
      <>
        <DetailList data={fieldGroup.groupedData} />
      </>
    );
  }

  function selectFieldGroupToRender(fieldGroup) {
    var fieldGroup;
    switch (fieldGroup.groupType) {
      case "DetailList":
        fieldGroup = DetailListGroup(fieldGroup);
        break;
      default:
        fieldGroup = DetailListGroup(fieldGroup);
        break;
    }

    return <>{fieldGroup}</>;
  }

  function renderFieldGroup(fieldGroup, fgIndex) {
    if (fieldGroup.hasOwnProperty("type") && fieldGroup.type == "group") {
      return <>{selectFieldGroupToRender(fieldGroup)}</>;
    }

    return fieldGroup.fields.map((field, fIndex) => (
      <S.InputWrapper key={fIndex}>
        {selectFieldToRender(fieldGroup, field)}
      </S.InputWrapper>
    ));
  }

  return (
    <>
      {!noForm ? (
        <Form headerLeft={config.headerLeft} headerRight={config.headerRight}>
          {config.fieldGroups.map((fieldGroup, fgIndex) => (
            <Fragment key={fgIndex}>
              <S.SectionHeader padding="40px 0 20px 0">
                {fieldGroup.header}
              </S.SectionHeader>
              {renderFieldGroup(fieldGroup, fgIndex)}
            </Fragment>
          ))}
        </Form>
      ) : (
        config.fieldGroups.map((fieldGroup, fgIndex) => (
          <Fragment key={fgIndex}>
            <S.SectionHeader padding="40px 0 20px 0">
              {fieldGroup.header}
            </S.SectionHeader>
            {renderFieldGroup(fieldGroup, fgIndex)}
          </Fragment>
        ))
      )}
    </>
  );
};

export default FormBuilder;
