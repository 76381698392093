import styled from "@emotion/styled";
import * as T from "../../../theme/quickspot-theme";

export const TextArea = styled.textarea`
  border: ${T.$border ? T.$border : "1px solid #0594A9"};
  border-radius: ${T.$fieldBorderRadius ? T.$fieldBorderRadius : "10px"};
  line-height: ${T.$lineHeight ? T.$lineHeight : "10px"};
  resize: none;

  :focus-visible {
    border: ${T.$border ? T.$border : "1px solid #0594A9"};
    border-radius: ${T.$fieldBorderRadius ? T.$fieldBorderRadius : "10px"};
    padding: 2px;
    outline: unset;
  }
`;
