import styled from "@emotion/styled";

export const FormDisplay = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 3px 5px #00000033, 0px -1px 10px #00000033;
  border-radius: 20px 20px 0px 0px;
  height: auto;
  border-radius: 20px;
`;

export const FormHeader = styled.div`
  width: 100%;
  background: #0594a9;
  color: #fff;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderLabel = styled.div`
  color: #fff;
  padding: 5px 20px;
  font-size: 22px;
`;
