import React from "react";
import headerImage from "../../../../src/images/ScriptHeader.png";
import signature from "../../../../src/images/signature.png";

const MedScript = (props) => {
  var today = new Date().toISOString().slice(0, 10);
  const styles = {
    page: {
      width: "600px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "12px",
      lineHeight: "20px",
      marginTop: "10px",
    },


    docSectionWrapper: {
      marginLeft: '25px',
      display: "flex",
      justifyContent: "left",
    },

    leftSection: {
      width: '50%',
    },
    rightSection: {
      width: '50%',
    },
    midSection: {},
    bottomSection: {
      marginLeft: '50px'
    },
  };

  return (
    <>
      <div style={styles.page}>
          <img style={{ objectFit: 'contain' , paddingLeft: '4px', width: "595px" }} src={headerImage} alt="" />
        <div style={styles.docSectionWrapper}>
          <div style={styles.leftSection}>
            <p>
              <strong>Dr Ilsa Orrey</strong>
            </p>
            <br />
          </div>
          <div style={styles.rightSection}>
            <p>
              Date: <strong>{today}</strong>
            </p>
            <br />
          </div>
        </div>

        <div style={styles.docSectionWrapper}>
          <div style={styles.leftSection}>
            <p>
              Patient Name: <strong>{props.name}</strong>
            </p>
            <br />
            <p>
              Date Of Birth: <strong>{props.age}</strong>
            </p>
          </div>
          <div style={styles.rightSection}>
            <p>
              Patient Email: <strong>{props.email}</strong>
            </p>
            <br />
            <p>
              Patient Phone: <strong>{props.phone}</strong>
            </p>
          </div>
        </div>
        <br />


        <div style={styles.docSectionWrapper}>
          <div style={styles.leftSection}>
            <p>
              Name of Medication: <strong>{props.medication}</strong>
            </p>
            <br />
            <p>
              Number of days to take : <strong> {props.how_long}</strong>
            </p>
            <br />
            <p>
              Number of Repeats : <strong> {props.script_repeats}</strong>
            </p>
            <br />
            <p>
              Instructions: Take {props.medication_type}  <strong>{props.dosage}, every {props.when}</strong> for {props.how_long} days.
            </p>
          </div>
          <div style={styles.rightSection}>
            <p>
            IDC-10 Code: <strong>{props.diagnosis}</strong>
            </p>
            <br />
            <p>
              No. scripts: <strong>1</strong>
            </p>
          </div>
        </div>
        <br />

        <div style={styles.bottomSection}>
          <img style={{ maxWidth: "100px" }} src={signature} alt="" />
          <br />
          Dr Ilsa Orrey
        </div>
      </div>
    </>
  );
};
export default MedScript;
