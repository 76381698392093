import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const PageWrap = styled.div`
  background: #fff;
  width: 100%;
  height: 100vh;
  flex-flow: column;
  display: flex;
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 320px;
  margin-bottom: 40px;
`;

export const LoginBox = styled.div`
  width: 400px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 10px;
`;

export const LinkButton = styled(Link)`
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  background-color: #0594a9;
  border: 0;
  transition: all 0.2s linear;
  min-width: 100px;
  text-align: center;
  text-decoration: none;
  color: white;
  cursor: pointer;
  outline: 0;
  padding: 12px 35px;
  font-size: 15px;
  @media (max-width: 1200px) {
    padding: 14px 28px;
  }

  &:hover,
  &.active {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 2px 16px 0px rgba(204, 204, 204, 1);
  }
`;

export const Button = styled.button`
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  background-color: #0594a9;
  border: 0;
  transition: all 0.2s linear;
  min-width: 100px;
  text-align: center;
  text-decoration: none;
  color: white;
  cursor: pointer;
  outline: 0;
  padding: 12px 35px;
  font-size: 15px;
  @media (max-width: 1200px) {
    padding: 14px 28px;
  }

  &:hover,
  &.active {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 2px 16px 0px rgba(204, 204, 204, 1);
  }
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #63bcc9;
  color: #9f9f9f;
  padding: 10px;
  font-family: Roboto;
  font-size: 16px;
  margin-bottom: 26px;
  box-sizing: border-box;
`;

export const LoginBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
