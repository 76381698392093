import React, { useState, useEffect } from "react";
import * as S from "./EmailPreview.styles";

const AcneFemaleEmailPreview = (props) => {
  const formData = props.formData;
  const requestData = props.requestData;

  const [previewData, setPreviewData] = useState("");
  const [form, setForm] = useState(null);

  const editableContent = {
    contentEditable: true,
    suppressContentEditableWarning: true,
    onKeyDown: breakline,
  };

  useEffect(() => {
    const defaultForm = {
      p1: "Dear ",
      p2: "Please see the results of your Quick Spot diagnosis below: ",
      p3: "Diagnosis: ",
      p4: "Medical Aid Code: ",
      p5: "There is scarring present and we therefore need to get your acne under control before we can treat your acne scars. ",
      p6: "There is no scarring present so we can treat your acne right away. ",
      p7: "To treat this condition, please see your script attached with the following instructions: ",
      p8: " repeats. ",
      p9: "No script is needed. ",
      p10: "I also recommend an additional script (also attached) with the following instructions: ",
      p11: " repeats. ",
      p12: "",
      p13: "Please be warned, that, should you fall pregnant while on Roaccutane, the baby will be very abnormal. Normally Roaccutane is never prescribed without adding contraception, so am therefore prescribing: ",
      p14: " repeats. ",
      p15: "No contraceptive needed. ",
      p16: "Alternatively, I can recommend the Acleara Acne Treatment for 4 sessions at R599 a session. ",
      p17: "Alternatively, I can recommend a Peel Acne Treatment for 4 sessions at R599 a session. ",
      p18: "Alternatively, I can recommend ",
      p19: "",
      p20: "Dr Orrey recommends a ",
      p21: "We will contact you shortly to arrange a time and date.",
      p22: "Please book NOW for a follow up appointment with me in ",
      p23: "No follow up appointment needed. ",
      p24: "Debora, our Medical Skin Therapist, will be reaching out to you to provide personalized recommendations for additional products to address your acne concerns.",
      p25: "Please be aware of the following side effects that the prescription medication may have: ",
      p26: "Severe dryness of the lips, skin & eyes. Skin may also be more sensitive to the sun. ",
      p27: "Please avoid any vitamin supplements containing vitamin A , and avoid omega 3 supplements. Alcohol should also be avoided. ",
      p28: "Please avoid any other topical retinoid or AHA/BHA's - other than what has been prescribed. ",
      p29: "Please stop all other acne related treatments. ",
      p30: "Please note that an SPF50 is necessary to combat sun sensitive skin. ",
      p31: "Please contact us should you have any unusual side effects. ",
      p32: "There should be no side effects. ",
      p33: "Kind regards,",
      p34: "Dr Orrey",
    };

    setForm(defaultForm);
    props.emailPreviewData(defaultForm);
  }, []);

  function breakline(event) {
    if (event.key === "Enter") {
      document.execCommand("insertLineBreak");
      event.preventDefault();
    }
  }

  function updatePreviewData(event) {
    form[event.target.getAttribute("data-p")] = event.target.innerHTML;
    props.emailPreviewData(form);
  }

  return (
    <>
      {form !== null && (
        <S.Main onInput={(e) => updatePreviewData(e)}>
          <S.P>
            <div data-p="p1" {...editableContent}>
              {form.p1}
            </div>
            {requestData.clients["name"]}
          </S.P>
          <br />
          <S.P>
            <div data-p="p2" {...editableContent}>
              {form.p2}
            </div>
          </S.P>

          <S.P>
            <S.Half>
              <div data-p="p3" {...editableContent}>
                {form.p3}
              </div>
              <S.Output>{formData["diagnose__diagnosis"]}</S.Output>
            </S.Half>
            <S.Half>
              <div data-p="p4" {...editableContent}>
                {form.p4}
              </div>
              <S.Output>{formData["diagnose__medcode"]}</S.Output>
            </S.Half>
          </S.P>

          <S.P>
            {formData["diagnose__scarring"] ? (
              <div data-p="p5" {...editableContent}>
                {form.p5}
              </div>
            ) : (
              <div data-p="p6" {...editableContent}>
                {form.p6}
              </div>
            )}
          </S.P>

          {formData["scriptgroup__script"] ? (
            <>
              <S.P data-p="p7" {...editableContent}>
                {form.p7}
              </S.P>
              <S.P>
                <S.Output>{formData["scriptgroup__name"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__type"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__dosage"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__when"]}</S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__howManyDays"]} { formData["scriptgroup__howManyDays"] ? 'days' : ''} </S.Output>
                &nbsp;
                <S.Output>{formData["scriptgroup__numRepeats"]}</S.Output>
                <div data-p="p8" {...editableContent}>
                  {form.p8}
                </div>
              </S.P>
            </>
          ) : (
            <S.P data-p="p9" {...editableContent}>
              {form.p9}
            </S.P>
          )}

          {formData["addscriptgroup__addScript"] ? (
            <>
              <S.P data-p="p10" {...editableContent}>
                {form.p10}
              </S.P>
              <S.P>
                <S.Output>{formData["addscriptgroup__name"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__type"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__dosage"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__when"]}</S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__howManyDays"]} { formData["addscriptgroup__howManyDays"] ? 'days' : ''} </S.Output>
                &nbsp;
                <S.Output>{formData["addscriptgroup__numRepeats"]}</S.Output>
                <div data-p="p11" {...editableContent}>
                  {form.p11}
                </div>
              </S.P>
            </>
          ) : (
            <S.P data-p="p12" {...editableContent}>
              {form.p12}
            </S.P>
          )}

          {formData["contraceptive__addScript"] ? (
            <>
              <S.P data-p="p13" {...editableContent}>
                {form.p13}
              </S.P>
              <S.P>
                <S.Output>{formData["contraceptive__name"]}</S.Output>
                &nbsp;
                <S.Output>{formData["contraceptive__type"]}</S.Output>
                &nbsp;
                <S.Output>{formData["contraceptive__dosage"]}</S.Output>
                &nbsp;
                <S.Output>{formData["contraceptive__when"]}</S.Output>
                &nbsp;
                <S.Output>{formData["contraceptive__howManyDays"]} { formData["contraceptive__howManyDays"] ? 'days' : ''}  </S.Output>
                &nbsp;
                <S.Output>{formData["contraceptive__numRepeats"]}</S.Output>
                <div data-p="p14" {...editableContent}>
                  {form.p14}
                </div>
              </S.P>
            </>
          ) : (
            <S.P data-p="p15" {...editableContent}>
              {form.p15}
            </S.P>
          )}

          {formData["addnotesgroup__altrec"] === "Acleara" && (
            <S.P data-p="p16" {...editableContent}>
              {form.p16}
            </S.P>
          )}

          {formData["addnotesgroup__altrec"] === "Peel" && (
            <S.P data-p="p17" {...editableContent}>
              {form.p17}
            </S.P>
          )}

          {formData["addnotesgroup__altrec"] === "Other" && (
            <S.P>
              <div data-p="p18" {...editableContent}>
                {form.p18}
              </div>
              <S.Output>{formData["addnotesgroup__altrecother"]}</S.Output>
            </S.P>
          )}
          {/*
          {formData["addnotesgroup__followupapp"] ? (
            <S.P>
              <div data-p="p22" {...editableContent}>
                {form.p22}
              </div>
              <S.Output>{formData["addnotesgroup__when"]}</S.Output>
              &nbsp;
              <S.Output>{formData["diagnose__when"]}</S.Output>.
            </S.P>
          ) : (
            <S.P data-p="p23" {...editableContent}>
              {form.p23}
            </S.P>
          )} */}

          {formData["addnotesgroup__recommendproduct"] ? (
            <S.P data-p="p24" {...editableContent}>
              {form.p24}
            </S.P>
          ) : (
            <></>
          )}

          {formData["scriptgroup__script"] ? (
            <>
              <S.P data-p="p25" {...editableContent}>
                {form.p25}
              </S.P>
              <S.P data-p="p26" {...editableContent}>
                {form.p26}
              </S.P>
              <S.P data-p="p27" {...editableContent}>
                {form.p27}
              </S.P>
              <S.P data-p="p28" {...editableContent}>
                {form.p28}
              </S.P>
              <S.P data-p="p29" {...editableContent}>
                {form.p29}
              </S.P>
              <S.P data-p="p30" {...editableContent}>
                {form.p30}
              </S.P>
              <S.P data-p="p31" {...editableContent}>
                {form.p31}
              </S.P>
            </>
          ) : (
            <S.P data-p="p32" {...editableContent}>
              {form.p32}
            </S.P>
          )}

          {formData["addnotesgroup__popappoint"] ? (
            <S.Block>
              <S.Contents data-p="p20" {...editableContent}>
                {form.p20}<S.Output>{formData["addnotesgroup__appointmenttype"]}</S.Output>. {form.p21}
              </S.Contents>
            </S.Block>
          ) : (
            <S.P data-p="p21" {...editableContent}>

            </S.P>
          )}

          {formData["addnotesgroup__addNotes"] && (
            <S.P>{formData["addnotesgroup__addNotes"]}</S.P>
          )}
          <br />
          <S.P data-p="p33" {...editableContent}>
            {form.p33}
          </S.P>

          <S.P>
            <div data-p="p34" {...editableContent}>
              {form.p34}
            </div>
          </S.P>
        </S.Main>
      )}
    </>
  );
};

export default AcneFemaleEmailPreview;
