import styled from "@emotion/styled";
import profilePic from "../../images/dermimg.jpg";
import { Link } from "react-router-dom";
import Button from "../../components/Base/Button/Button";
import Form from "../../components/Simple/Form/Form";

/* export const MainContentContainer = styled.div`
	width: 100%;
	display: flex;
	margin: 0 auto;
`; */

export const BtnLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

export const Half = styled.div`
  width: 50%;
  display: flex;
  margin: 0 auto;
  /* padding: 10px 5%; */
  padding: 10px 1vw;
  justify-content: space-between;
`;

/* export const TopLeftHalf = styled(Half)`
	align-items: center;
`; */

export const TopLeftHalf = styled(Half)`
  margin: 0 auto;
  padding: 0;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const Top = styled(Half)`
  width: 100%;
  margin: 0 auto;
  padding: 10px 1vw;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const boxHalf = styled(Half)`
  width: 98%;
  margin: 0;
  padding: 10px 1vw;
  align-items: center;
  flex-direction: column;
  height: 100%;
  /* overflow: auto; */
  overflow: auto;
`;

export const boxHalfOverflow = styled(Half)`
  width: 98%;
  margin: 0;
  padding: 0;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const MidHalf = styled(Half)`
  overflow-y: auto;
  justify-content: normal;
  flex-direction: column;
`;

export const LeftMidHalf = styled(Half)`
  padding: 0;
`;

export const OverflowContainer = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 10px 5%;
	overflow-y: auto;
	justify-content: normal;
	flex-direction: column; */
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;

export const CenterButtonWrapper = styled(ButtonWrapper)`
  margin: 20px auto;
  justify-content: center;
`;

/* -------------------------------------------- */

export const Page = styled.div`
  height: 100vh;
  margin: 0;
  display: flex;
  flex-flow: column;
`;

/* export const Header = styled.div`
	flex: 0 1 auto;
	display: flex;
`; */

export const Header = styled.div`
  flex: 0 1 auto;
  display: flex;
  height: 100%;
  /* margin: 20px 0 10px 0; */
  flex-direction: column;
`;

export const Header2 = styled.div`
  flex: 0 1 auto;
  display: flex;
  height: calc(60% - 20px);
  /* margin: 20px 0 10px 0; */
`;

export const MainContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
`;

export const Footer = styled.div`
  flex: 0 1 40px;
`;

export const SectionHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: ${(props) => (props.padding ? props.padding : "100px 0 20px 0")};
`;

export const InputWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 0 auto;
`;

export const Text = styled.input`
  border: 1px solid #0594a9;
  border-radius: 10px;

  :focus-visible {
    border: 1px solid #0594a9;
    border-radius: 10px;
    padding: 1px 5px;
    outline: unset;
  }
`;

export const Label = styled.label``;

export const DermProfileImg = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  background-image: url(${profilePic});
  background-position: center;
  background-size: cover;
  margin: 40px;
`;

export const BtnToolbar = styled.div`
  width: 100%;
  background: #fff;
  /* background: #d8e7e9; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  bottom: 0px;
  font-size: 20px;
  line-height: 26px;
  flex: 0 1 auto;
`;

export const BtnToolbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  /* margin: 10px auto; */
  margin: 8px auto;
  justify-content: center;
`;

export const BtnToolbarHalf = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
`;

/* export const Btn = styled(Button)`
	margin: 0px 10px;
	padding: 50px;
	fontSize: 50px;
`; */

export const Btn = styled(Button)`
  margin: 0px 10px;
`;

export const Separator = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const PatientRequestImg = styled.div`
  /* width: 400px;
  height: 400px; */
  width: 200px;
  height: 200px;
  background-image: url(${(props) =>
    props.backgroundImg ? props.backgroundImg : ""});
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  margin: 20px;
  width: 30vw;
  height: 50vh;
  background-repeat: no-repeat;
`;

export const MarginDiv = styled.div`
  margin: 10px;
`;

export const FullHeightForm = styled(Form)`
  /* min-height: 100%; */
  height: 100%;
`;

export const SideFlex = styled.div`
  display: flex;
`;
