import React from "react";
import * as S from "./Form.styles";
const Form = ({ children, ...props }) => {
  const childElements = children;
  const headerLeft = props.headerLeft;
  const headerRight = props.headerRight;
  const displayHeader =
    props.hasOwnProperty("properties") &&
    props.properties.hasOwnProperty("displayHeader")
      ? props.properties.displayHeader
      : props.hasOwnProperty("displayHeader")
      ? props.displayHeader
      : true;
  const properties = props.properties;

  return (
    <S.FormDisplay {...props} properties={properties}>
      {displayHeader && (
        <S.FormHeader>
          <S.HeaderLabel>{headerLeft}</S.HeaderLabel>
          <S.HeaderLabel>{headerRight}</S.HeaderLabel>
        </S.FormHeader>
      )}
      {childElements}
    </S.FormDisplay>
  );
};

export default Form;
