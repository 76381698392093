import React, { useState } from "react";
import * as S from "./RadioBtnGroup.styles";

const RadioBtnGroup = ({ ...props }) => {
  function getProp(key) {
    let styling = props.styling ? props.styling : "";
    let stylingProp = styling ? styling[key] : "";

    return props[key] ? props[key] : stylingProp;
  }

  function btnClick(e, btnOpValue) {
    e.target["value"] = btnOpValue;
    e.target["name"] = props.name;
    setCurrent(btnOpValue);
    props.onClick(e);
  }

  const dotSize = getProp("dotSize");
  const backgroundColor = getProp("backgroundColor");
  const buttonOptions = props.buttonOptions;
  const defaultVal =
    props.defaultVal || props.defaultVal === 0 ? props.defaultVal : "Yes";

  const [current, setCurrent] = useState(defaultVal);

  return (
    <S.RadioBtnGroup {...props}>
      {buttonOptions.map((buttonOption, index) => (
        <S.RadioWrapper key={index}>
          <S.RadioIcon
            dotSize={dotSize}
            backgroundColor={backgroundColor}
            name={props.name}
            onClick={(e) => btnClick(e, buttonOption.value)}
            checked={current === buttonOption.value}
            /* checked={current === index} */
          />
          <S.RadioLabel type="radio">{buttonOption.label}</S.RadioLabel>
        </S.RadioWrapper>
      ))}
    </S.RadioBtnGroup>
  );
};

export default RadioBtnGroup;
