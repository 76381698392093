import styled from "@emotion/styled";
import SearchImg from "../../../images/search.png";

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 0px 5px 10px;
`;

export const SearchContainer = styled.div`
  width: 100%;
  /* padding: 20px; */
  padding: 0px 20px 20px;
`;

export const SearchIcon = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url(${SearchImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 10px;
`;

export const Search = styled.input`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #0594a9;
  font-size: 16px;
  padding: 10px 0px 10px 40px;
  color: #0594a9;
  box-sizing: border-box;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #0594a9;
  }
`;
