import React from "react";
import * as S from "./CornerLogo.styles";
const CornerLogo = (props) => {
  return (
    <>
      <S.LogoContainer width={props.width} height={props.height}>
        <S.Logo
          width={props.logoWidth}
          height={props.logoHeight}
          margin={props.logoMargin}
        ></S.Logo>
      </S.LogoContainer>
    </>
  );
};

export default CornerLogo;
