import styled from "@emotion/styled";

export const Main = styled.div`
  width: 100%;
  padding: 20px;
  box-shadow: 0px 3px 5px #00000033, 0px -1px 10px #00000033;
`;

export const P = styled.div`
  display: flex;
  white-space: pre-wrap;
  margin-bottom: 1.5em;
  &:last-child {
    margin: 0;
  }

  div {
    display: flex;
  }
`;

export const Output = styled.div`
  border: ${(props) => (props.children ? "unset" : "1px solid #f73030")};
  border-radius: ${(props) => (props.children ? "unset" : "5px")};
  min-width: ${(props) => (props.children ? "unset" : "50px")};
`;

export const Half = styled.div`
  width: 50%;
  display: flex;
`;

export const Block = styled(P)`
  display: block;
`;

export const Contents = styled.div`
  display: inline;
`;
