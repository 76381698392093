import React, { useState, useEffect, useRef } from "react";
import * as S from "./EmailSetup.styles";
import Form from "../../components/Simple/Form/Form";
import DetailList from "../../components/Simple/DetailList/DetailList";
import FilteredDetailList from "../../components/Simple/FilteredDetailList/FilteredDetailList";
import MedScript from "../../components/Simple/MedScript/MedScript";
import RashEmailFormConfig from "../../configs/FormConfigs/RashEmailFormConfig";
import SpotEmailFormConfig from "../../configs/FormConfigs/SpotEmailFormConfig";
import AcneMaleEmailFormConfig from "../../configs/FormConfigs/AcneMaleEmailFormConfig";
import AcneFemaleEmailFormConfig from "../../configs/FormConfigs/AcneFemaleEmailFormConfig";
import FormBuilder from "../../components/Compound/FormBuilder/FormBuilder";
import { useNavigate } from "react-router-dom";

import RashEmailPreview from "../../configs/EmailConfigs/RashEmailPreview";
import SpotEmailPreview from "../../configs/EmailConfigs/SpotEmailPreview";
import AcneMaleEmailPreview from "../../configs/EmailConfigs/AcneMaleEmailPreview";
import AcneFemaleEmailPreview from "../../configs/EmailConfigs/AcneFemaleEmailPreview";
import Modal from "../../components/Simple/Modal/Modal";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js/src";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import TablesObject from "../../configs/FormConfigs/TablesObject";
import { TextArea } from "../../components/Base/TextArea/TextArea.styles";
import emailjs from "@emailjs/browser";

import ImageUpload from "../../components/Base/ImageUpload/ImageUpload";

const EmailSetup = () => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const scriptAttachmentRef = useRef(null);
  const scriptAttachmentRefAdditional = useRef(null);

  /* const ffd = flattenFormData(RashEmailFormConfig);
  const [formData, setFormData] = useState(ffd); */
  const [submissionType, setSubmissionType] = useState(""); //acne rash or spot?
  const [emailPreviewData, setEmailPreviewData] = useState("");
  const [clientInfo, setSetClientInfo] = useState(null);
  const [clientData, setSetClientData] = useState(null);
  const [displayModalSuccessMsg, setDisplayModalSuccessMsg] = useState(false);
  const [displayImgModal, setDisplayImgModal] = useState(false);
  const [displayPatientModal, setDisplayPatientModal] = useState(false);
  const [imgsSrc, setImgsSrc] = useState([]);
  const [displayRejectModel, setDisplayRejectModel] = useState(false);
  const [diagnosisType, setDiagnosisType] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [displaySendRejectEmailModal, setDisplaySendRejectEmailModal] =
      useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [modelID, setModelID] = useState(false);
  const [dataState, setDataState] = useState({
    formData: null,
    emailFormConfig: null,
    requestCategory: null,
    data: null,
  });

  const ImageSuccess = (data) => {
    //Update record in DB
    updateDiagnosisImage(data.info.url);
    // setImagesArr((current) => [...current, data.info.url]);
    // setThumbsArr((current) => [...current, data.info.thumbnail_url]);
  };

  const ImageFailure = (data) => {
    console.log(data, "failure");
    alert(
        "An error occured with the upload widget, please refresh and try again"
    );
  };

  useEffect(() => {
    const getRequests = async () => {
      try {
        const result = await axios.get(
            `${process.env.REACT_APP_REACT_APP_APIURL}/request/${state.requestId}`
        );
        //console.log("result: ", result.data);
        //result.data.clients.sex == 'Female' || 'Male'
        sortRequestData(result.data);
      } catch (error) {
        alert(error);
        if (error.response) {
          alert(error.response.data.msg);
        }
      }
    };

    if (state.hasOwnProperty("requestId")) {
      getRequests();
    }
  }, [imageUploaded]);

  function sortRequestData(data) {
    var dataTempState;
    if (data.acne) {
      setSubmissionType("Acne");
      setModelID(data.acne.id);
      const mailFormCon =
          data.clients.sex === "female"
              ? AcneFemaleEmailFormConfig
              : AcneMaleEmailFormConfig;

      dataTempState = {
        formData: flattenFormData(mailFormCon),
        emailFormConfig: mailFormCon,
        requestCategory: "acne",
        data: data,
      };
    } else if (data.rash) {
      setSubmissionType("Rash");
      setModelID(data.rash.id);
      dataTempState = {
        formData: flattenFormData(RashEmailFormConfig),
        emailFormConfig: RashEmailFormConfig,
        requestCategory: "rash",
        data: data,
      };
    } else if (data.spot) {
      setSubmissionType("Spot");
      setModelID(data.spot.id);
      //console.log("SPOTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT");
      dataTempState = {
        formData: flattenFormData(SpotEmailFormConfig),
        emailFormConfig: SpotEmailFormConfig,
        requestCategory: "spot",
        data: data,
      };
    } else {
      /* requestCategory = 'none';
      ffd = {}; */
      dataTempState = {
        formData: flattenFormData({}),
        emailFormConfig: null,
        requestCategory: "none",
        data: data,
      };
    }

    if (dataTempState.requestCategory != "none") {
      const dataCategory = {
        details: [],
        info: [],
        med: [],
      };

      const clientInfo = [];

      Object.keys(data[dataTempState.requestCategory]).map((key) => {
        Object.keys(dataCategory).map((dataKey) => {
          if (
              TablesObject()[`${dataTempState.requestCategory}-table`][
                  dataKey
                  ].hasOwnProperty(key)
          ) {
            dataCategory[dataKey].push({
              text: TablesObject()[`${dataTempState.requestCategory}-table`][
                  dataKey
                  ][key],
              value:
                  data[dataTempState.requestCategory][key] === 1
                      ? "Yes"
                      : data[dataTempState.requestCategory][key] === 0
                          ? "No"
                          : data[dataTempState.requestCategory][key],
            });
          }
        });
      });

      Object.keys(data["clients"]).map((key) => {
        if (
            TablesObject()[`${dataTempState.requestCategory}-table`][
                "client"
                ].hasOwnProperty(key)
        ) {
          clientInfo.push({
            text: TablesObject()[`${dataTempState.requestCategory}-table`][
                "client"
                ][key],
            value: data["clients"][key] === 1 ? "Yes" : data["clients"][key] === 0 ? "No" : !data["clients"][key] ? 'Not Available' : data["clients"][key],
          });
        }
      });



      setDataState({
        formData: dataTempState.formData,
        emailFormConfig: dataTempState.emailFormConfig,
        requestCategory: dataTempState.requestCategory,
        data: dataTempState.data,
      });

      setSetClientInfo([{ header: "Patient Details", data: clientInfo }]);
      setSetClientData([
        {
          header: `Information about the ${dataTempState.requestCategory}`,
          data: dataCategory["info"],
        },
        {
          header: "Information about the medication",
          data: dataCategory["med"],
        },
      ]);

      const Images = JSON.parse(data[dataTempState.requestCategory]["images"]);

      setImgsSrc(Images);
    }
  }

  function flattenFormData(esfConfig) {
    let flatObj = {};
    esfConfig.fieldGroups.map((fieldGroup) => {
      if (fieldGroup.type == "field") {
        fieldGroup.fields.map((field) => {
          if (field.hasOwnProperty("fieldName") && field.fieldName) {
            if (field.hasOwnProperty("default") && field.default) {
              flatObj[`${fieldGroup.groupName}__${field.fieldName}`] =
                  field.default;
            } else {
              flatObj[`${fieldGroup.groupName}__${field.fieldName}`] = "";
            }
          }
        });
      }
    });
    return flatObj;
  }

  const updateField = (e) => {
    const { name, value } = e.target;

    setDataState((prevState) => {
      const { formData } = prevState;
      formData[name] = value;
      return { ...prevState, formData };
    });
  };

  function FormBuilderCallback(childData) {
    updateField(childData);
  }

  function modalSuccessMsg() {
    prepareDataForEmail("diagnosed");

    return (
        <>
          <Modal
              /* headerLeft={RashEmailFormConfig.headerLeft}
            headerRight={RashEmailFormConfig.headerRight}  */
              properties={{ height: "100%", borderRadius: "20px" }}
              // confirmBtn={{ label: "Okay", nav: "/dashboard" }}
              // cancelBtn={{
              //   label: "Cancel",
              //   exitModal: true,
              //   callbackHandler: (data) => {
              //     setDisplayModalSuccessMsg(data);
              //   },
              // }}
              confirmBtn={false}
              cancelBtn={false}
          >
            <S.MarginDiv>Successful Diagnosis!</S.MarginDiv>

            <S.MarginDiv>
              Your diagnosis has been sucessful. An email will be sent to the
              referring doctor.
            </S.MarginDiv>

            <S.Btn onClick={() => setDisplayModalSuccessMsg(false)}>Finished</S.Btn>
          </Modal>
        </>
    );
  }

  function imgModal() {

    //Before displaying images loop through and replace file extension with jpg
    imgsSrc.forEach((value, index) => {
      let ext = value.substring(value.lastIndexOf(".")+1);
      let newString = value.replace(ext, "jpg");
      imgsSrc[index] = newString
    });

    return (
        <>
          <Modal
              properties={{
                height: "100%",
                borderRadius: "20px",
                displayHeader: false,
              }}
              cancelBtn={{
                label: "Close",
                exitModal: true,
                callbackHandler: (data) => {
                  setDisplayImgModal(data);
                },
              }}
              width="auto"
          >
            {/* <S.SideFlex>
            {imgsSrc.map((img) => (
              <div style={{ marginTop: "10px", display:"inline-grid" }}>
                <a
                  style={{ marginLeft: "50%" }}
                  href={img}
                  download
                  target="_blank"
                >
                  Download
                </a>
                <S.PatientRequestImg backgroundImg={img}></S.PatientRequestImg>
              </div>
            ))}
            <br />
          </S.SideFlex> */}
            <div
                style={{
                  display: "inline-grid",
                  overflowY: "scroll",
                  marginTop: "10px",
                  textAlign: "center",
                }}
            >
              {imgsSrc.length === 0 ? (
                  <div
                      style={{ width: "50vw", height: "65vh", padding: "10px" }}
                  ><p style={{ paddingTop: "50%" }}>No images were uploaded</p></div>
              ) : (
                  ""
              )}
              {imgsSrc.map((img) => (
                  <div>
                    <img
                        src={img}
                        style={{ width: "100%", height: "60vh", padding: "10px" }}
                    ></img>
                    <a href={img} download target="_blank">
                      Download
                    </a>
                  </div>
              ))}
            </div>
            <div style={{ marginTop: "10px" }}>
              <ImageUpload onSuccess={ImageSuccess} onFailure={ImageFailure} />
            </div>
          </Modal>
        </>
    );
  }

  function patientModal() {
    return (
        <>
          <Modal
              properties={{
                height: "100%",
                borderRadius: "20px",
                displayHeader: false,
                width: "100%",
              }}
              cancelBtn={{
                label: "Close",
                exitModal: true,
                callbackHandler: (data) => {
                  setDisplayPatientModal(data);
                },
              }}
          >
            {clientInfo && <DetailList data={clientInfo} />}
          </Modal>
        </>
    );
  }

  function rejectModal() {
    var header;
    if (diagnosisType === "Reject") {
      header = "Reason For Rejection";
    } else {
      header = "Additional Info Required";
    }

    return (
        <>
          <Modal
              headerLeft={header}
              properties={{ height: "100%", borderRadius: "20px", width: "100%" }}
              confirmBtn={{
                label: "Send",
                exitModal: true,
                callbackHandler: (data) => {
                  sendRejectEmailModal("Reject");
                  sendRejectEmail(rejectReason);
                  setDisplayRejectModel(data);
                  setDisplaySendRejectEmailModal(true);
                },
              }}
              cancelBtn={{
                label: "Cancel",
                exitModal: true,
                callbackHandler: (data) => {
                  setDisplayRejectModel(data);
                },
              }}
          >
            <S.MarginDiv>Reason:</S.MarginDiv>

            <S.MarginDiv>
              <TextArea
                  rows="20"
                  cols="100"
                  onChange={(e) => setRejectReason(e.currentTarget.value)}
              ></TextArea>
            </S.MarginDiv>
          </Modal>
        </>
    );
  }

  function sendRejectEmailModal() {
    prepareDataForEmail(diagnosisType);

    return (
        <>
          {
            <Modal
                headerLeft="Success"
                properties={{ height: "100%", borderRadius: "20px", width: "100%" }}
                confirmBtn={{ label: "Close", nav: "/dashboard" }}
            >
              <S.MarginDiv>Email has been sent!</S.MarginDiv>
            </Modal>
          }
        </>
    );
  }

  function sendRejectEmail(rejectReason) {
    //console.log("This is where the email gets sent: ", rejectReason);
  }

  async function updateDiagnosisStatus(type, emailData) {
    try {
      const result = await axios.post(
          `${process.env.REACT_APP_REACT_APP_APIURL}/updateDiagnosisStatus`,
          {
            id: state.requestId,
            type: type,
            emailData: JSON.stringify(emailData),
          }
      );
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.data.msg);
      }
    }
  }

  async function updateDiagnosisImage(imageSrc) {
    try {
      const result = await axios.post(
          `${process.env.REACT_APP_REACT_APP_APIURL}/updateDiagnosisImage`,
          {
            id: modelID,
            type: submissionType,
            imageSrc: imageSrc,
          }
      );
      // console.log("result: ", result.data);
      setImageUploaded(true);
    } catch (error) {
      alert(error);
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  }

  function updateEmailPreviewData(data) {
    setEmailPreviewData(data);
  }

  function displayEmailPreviewForm() {

    return (
        <>
          {dataState.requestCategory === "acne" ? (
              <>
                {(dataState.data.clients.sex !== "female" && (
                        <AcneMaleEmailPreview
                            formData={dataState.formData}
                            requestData={dataState.data}
                            emailPreviewData={updateEmailPreviewData}
                        />
                    )) ||
                    (dataState.data.clients.sex === "female" && (
                        <AcneFemaleEmailPreview
                            formData={dataState.formData}
                            requestData={dataState.data}
                            emailPreviewData={updateEmailPreviewData}
                        />
                    ))}
              </>
          ) : (
              <>
                {(dataState.requestCategory === "rash" && (
                        <RashEmailPreview
                            formData={dataState.formData}
                            requestData={dataState.data}
                            emailPreviewData={updateEmailPreviewData}
                        />
                    )) ||
                    (dataState.requestCategory === "spot" && (
                        <SpotEmailPreview
                            formData={dataState.formData}
                            requestData={dataState.data}
                            emailPreviewData={updateEmailPreviewData}
                        />
                    ))}{" "}
              </>
          )}
        </>
    );
  }

  const handleGeneratePdf = async () => {
    // ReactPDF.renderToStream(<MedScript />);
    const content = scriptAttachmentRef.current;
    return content
  };

  const handleGenerateAdditionalPdf = async => {
    const content = scriptAttachmentRefAdditional.current;
    return content;
  }
  async function prepareDataForEmail(type) {
    let epd = emailPreviewData;
    let fd = dataState.formData;
    let groupedData;

    if (dataState.requestCategory === "rash") {
      groupedData = {
        g1: epd.p1 + dataState.data.clients.name,
        g2: epd.p2,
        g3: epd.p3 + fd.diagnose__diagnosis,
        g4: epd.p4 + fd.diagnose__medcode,
        g5: fd.scriptgroup__script
            ? epd.p5 +
            fd.scriptgroup__name +
            " " +
            fd.scriptgroup__type +
            " " +
            fd.scriptgroup__dosage +
            " " +
            fd.scriptgroup__when +
            " " +
            fd.scriptgroup__howManyDays +
            " " +
            fd.scriptgroup__numRepeats +
            epd.p6
            : epd.p7,
        g6: fd.addscriptgroup__addScript
            ? epd.p8 +
            fd.addscriptgroup__name +
            " " +
            fd.addscriptgroup__type +
            " " +
            fd.addscriptgroup__dosage +
            " " +
            fd.addscriptgroup__when +
            " " +
            fd.addscriptgroup__howManyDays +
            " " +
            fd.addscriptgroup__numRepeats +
            epd.p9
            : epd.p10,
        g7: fd.diagnose__popappoint
            ? epd.p11 + fd.diagnose__approxcost + epd.p12
            : epd.p13,
        g8: fd.diagnose__followupapp
            ? epd.p14 + fd.diagnose__appointmenttype + ". " + epd.p15
            : "",
        g9: fd.addnotesgroup__addNotes,
        g10: epd.p16,
        g11: epd.p17,
      };
    } else if (dataState.requestCategory === "spot") {
      groupedData = {
        g1: epd.p1 + dataState.data.clients.name,
        g2: epd.p2,
        g3: epd.p3 + fd.diagnose__diagnosis,
        g4: epd.p4 + fd.diagnose__medcode,
        g5: fd.diagnose__removespot
            ? epd.p5 +
            fd.diagnose__cancerous +
            epd.p6 +
            epd.p7 +
            fd.diagnose__typeremoval +
            "."
            : epd.p8 + fd.diagnose__cancerous + epd.p9,
        g6: fd.addscriptgroup__biopsy ? epd.p10 : epd.p11,
        g7: fd.diagnose__popappoint
            ? epd.p12 + fd.diagnose__appointmenttype + ". " + epd.p13
            : "",
        g8: fd.diagnose__followupapp
            ? epd.p15 + fd.diagnose__reason + " " + fd.diagnose__when
            : epd.p16,
        g9: fd.scriptgroup__script
            ? epd.p17 +
            fd.scriptgroup__name +
            " " +
            fd.scriptgroup__type +
            " " +
            fd.scriptgroup__dosage +
            " " +
            fd.scriptgroup__when +
            " " +
            fd.scriptgroup__howManyDays +
            " " +
            fd.scriptgroup__numRepeats +
            epd.p18
            : epd.p19,
        g10: fd.addnotesgroup__altrec
            ? epd.p20 + fd.addnotesgroup__elaborate
            : "",
        g11: fd.addnotesgroup__addNotes ? fd.addnotesgroup__addNotes : "",
        g12: epd.p21,
        g13: epd.p22,
      };
    } else if (dataState.requestCategory === "acne") {
      if (dataState.data.clients.sex === "male") {
        groupedData = {
          g1: epd.p1 + dataState.data.clients.name,
          g2: epd.p2,
          g3: epd.p3 + fd.diagnose__diagnosis,
          g4: epd.p4 + fd.diagnose__medcode,
          g5: fd.diagnose__scarring ? epd.p5 : epd.p6,
          g6: fd.scriptgroup__script
              ? epd.p7 +
              fd.scriptgroup__name +
              " " +
              fd.scriptgroup__type +
              " " +
              fd.scriptgroup__dosage +
              " " +
              fd.scriptgroup__when +
              " " +
              fd.scriptgroup__howManyDays +
              " " +
              fd.scriptgroup__numRepeats +
              epd.p8
              : epd.p9,
          g7: fd.addscriptgroup__addScript
              ? epd.p10 +
              fd.addscriptgroup__name +
              " " +
              fd.addscriptgroup__type +
              " " +
              fd.addscriptgroup__dosage +
              " " +
              fd.addscriptgroup__when +
              " " +
              fd.addscriptgroup__howManyDays +
              " " +
              fd.addscriptgroup__numRepeats +
              epd.p11
              : epd.p12,
          g8:
              fd.addnotesgroup__altrec === "Acleara"
                  ? epd.p13
                  : fd.addnotesgroup__altrec === "Peel"
                      ? epd.p14
                      : fd.addnotesgroup__altrec === "Other"
                          ? epd.p15 + fd.addnotesgroup__altrecother
                          : "",
          g9: fd.addnotesgroup__popappoint
              ? epd.p17 + fd.addnotesgroup__appointmenttype + ". " + epd.p18
              : "",
          g10: "",
          g11: fd.addnotesgroup__recommendproduct ? epd.p21 : "",
          g12: fd.addnotesgroup__addNotes ? fd.addnotesgroup__addNotes : "",
          g13: fd.scriptgroup__script
              ? epd.p22 +
              epd.p23 +
              epd.p24 +
              epd.p25 +
              epd.p26 +
              epd.p27 +
              epd.p28
              : epd.p29,
          g14: "",
          g15: epd.p30,
          g16: epd.p31,
        };
      } else {
        groupedData = {
          g1: epd.p1 + dataState.data.clients.name,
          g2: epd.p2,
          g3: epd.p3 + fd.diagnose__diagnosis,
          g4: epd.p4 + fd.diagnose__medcode,
          g5: fd.diagnose__scarring ? epd.p5 : epd.p6,
          g6: fd.scriptgroup__script
              ? epd.p7 +
              fd.scriptgroup__name +
              " " +
              fd.scriptgroup__type +
              " " +
              fd.scriptgroup__dosage +
              " " +
              fd.scriptgroup__when +
              " " +
              fd.scriptgroup__howManyDays +
              " " +
              fd.scriptgroup__numRepeats +
              epd.p8
              : epd.p9,
          g7: fd.addscriptgroup__addScript
              ? epd.p10 +
              fd.addscriptgroup__name +
              " " +
              fd.addscriptgroup__type +
              " " +
              fd.addscriptgroup__dosage +
              " " +
              fd.addscriptgroup__when +
              " " +
              fd.addscriptgroup__howManyDays +
              " " +
              fd.addscriptgroup__numRepeats +
              epd.p11
              : epd.p12,
          g8: fd.contraceptive__addScript
              ? epd.p13 +
              fd.contraceptive__name +
              " " +
              fd.contraceptive__type +
              " " +
              fd.contraceptive__dosage +
              " " +
              fd.contraceptive__when +
              " " +
              fd.contraceptive__howManyDays +
              " " +
              fd.contraceptive__numRepeats +
              epd.p14
              : epd.p15,
          g9:
              fd.addnotesgroup__altrec === "Acleara"
                  ? epd.p16
                  : fd.addnotesgroup__altrec === "Peel"
                      ? epd.p17
                      : fd.addnotesgroup__altrec === "Other"
                          ? epd.p18 + fd.addnotesgroup__altrecother
                          : "",
          g10: fd.addnotesgroup__popappoint ? epd.p20 + fd.addnotesgroup__appointmenttype + ". " + epd.p21
              : "",
          g11: "",
          g12: fd.addnotesgroup__recommendproduct ? epd.p24 : "",
          g13: fd.addnotesgroup__addNotes ? fd.addnotesgroup__addNotes : "",
          g14: fd.scriptgroup__script
              ? epd.p25 +
              epd.p26 +
              epd.p27 +
              epd.p28 +
              epd.p29 +
              epd.p30 +
              epd.p31
              : epd.p32,
          g15: epd.p33,
          g16: epd.p34,
        };
      }
    }


    const formattedClientInfo = FormatClientInfo(clientInfo[0].data);
    const script_content = await handleGeneratePdf();

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: 'a4',
      compress: false,
    });
    // pdf.setFont("arial", "bold");
    // pdf.setFont('Inter-Regular', 'normal');
    await pdf.html(script_content, {
      async callback(pdf) {
        //  console.log('finished deployment of pdf file')
        pdf.output('datauristring');
        // console.log('output after created ; ',pdf.output('datauristring'))
      },
    });

    const output = pdf.output('datauristring');
    // console.log('output after : ');
    // console.log(output)
    //now send finalObject to Emailjs
    const image = await html2canvas(script_content);
    await saveDiagnosisImage(image.toDataURL("image/jpeg"), groupedData);

    const mainScript = dataState.formData.scriptgroup__script
    const additionScript = dataState.formData.addscriptgroup__addScript

    let finalObject;

    if (mainScript || additionScript) {
      finalObject = {
        ...groupedData,
        ...formattedClientInfo,
        scriptAttachment: output,
      };
    } else {
      finalObject = {
        ...groupedData,
        ...formattedClientInfo
      };
    }

    let templateID = ''

    if(dataState.requestCategory === "rash") {
      templateID = 'template_0wk56ki'
    } else if (dataState.requestCategory === "spot") {
      templateID = 'template_b1mc0ox'
    } else if(dataState.requestCategory === "acne") {
      templateID = 'template_iktd8jf'
    }

    emailjs
        .send(
            "service_3s2afei",
            templateID,
            finalObject,
            "7Kldl53BlTVbRv8x6"
        )
        .then(
            (result) => {
              console.log("Great success");
            },
            (error) => {
              console.log(error.text);
            }
        );

    updateDiagnosisStatus(type, groupedData);

    return finalObject;
  }

  const saveDiagnosisImage = async (image, data) => {
    try {
      const result = await axios.post(
          `${process.env.REACT_APP_REACT_APP_APIURL}/saveDiagnosisImage`,
          {
            userId: state.requestId,
            imageSrc: image,
            diagnosisData: JSON.stringify(data)
          }
      );
    } catch (error) {
      alert(error);
    }
  }

  const FormatClientInfo = (clientInfo) => {
    const reducedObject = clientInfo.reduce(
        (obj, item) =>
            Object.assign(obj, {
              [item.text.toLowerCase().replace(/\s/g, "")]: item.value,
            }),
        {}
    );
    return reducedObject;
  };
  // console.log(dataState.formData, "dataState.formData");
  return (
      <S.Page>
        <S.MainContent>
          <div style={{ overflow: "hidden", position: "absolute" }}>
            <div style={{ position: "absolute"}}>
              <div ref={scriptAttachmentRef}>
                <MedScript
                    name={dataState?.data?.clients?.name ?? ""}
                    email={dataState?.data?.clients?.email ?? ""}
                    phone={dataState?.data?.clients?.phone ?? ""}
                    age={dataState?.data?.clients?.age ?? ""}
                    medication={dataState?.formData?.scriptgroup__name ?? ""}
                    dosage={dataState?.formData?.scriptgroup__dosage ?? ""}
                    medication_type={dataState?.formData?.scriptgroup__type ?? ""}
                    when={dataState?.formData?.scriptgroup__when ?? ""}
                    how_long={dataState?.formData?.scriptgroup__howManyDays ?? ""}
                    script_repeats={dataState?.formData?.scriptgroup__numRepeats ?? ""}
                    diagnosis={dataState?.formData?.diagnose__medcode ?? ""}
                />
              </div>
              <div ref={scriptAttachmentRefAdditional}>
                <MedScript
                    name={dataState?.data?.clients?.name ?? ""}
                    email={dataState?.data?.clients?.email ?? ""}
                    phone={dataState?.data?.clients?.phone ?? ""}
                    age={dataState?.data?.clients?.age ?? ""}
                    medication={dataState?.formData?.addscriptgroup__name ?? ""}
                    dosage={dataState?.formData?.addscriptgroup__dosage ?? ""}
                    medication_type={dataState?.formData?.addscriptgroup__type ?? ""}
                    when={dataState?.formData?.addscriptgroup__when ?? ""}
                    script_repeats={dataState?.formData?.addscriptgroup__numRepeats ?? ""}
                    diagnosis={""}
                />
              </div>
            </div>
          </div>
          {displayModalSuccessMsg && modalSuccessMsg()}
          {displayImgModal && imgModal()}
          {displayPatientModal && patientModal()}
          {displayRejectModel && rejectModal()}
          {displaySendRejectEmailModal && sendRejectEmailModal()}
          {/* modal popup{ clientInfo && <DetailList data={clientInfo}/>} */}
          <S.LeftMidHalf>
            <S.OverflowContainer>
              <S.Header>
                <S.Top>
                  <Form displayHeader={false}>
                    {clientData && <FilteredDetailList data={clientData} />}
                    {/* Highlight 2nd rows example: background: #e3fafd; */}
                  </Form>
                </S.Top>
              </S.Header>
            </S.OverflowContainer>
          </S.LeftMidHalf>

          <S.MidHalf>
            <S.OverflowContainer>
              <S.FullHeightForm displayHeader={false}>
                <S.boxHalf>{displayEmailPreviewForm()}</S.boxHalf>

                <S.boxHalfOverflow>
                  {dataState.emailFormConfig && dataState.formData && (
                      <FormBuilder
                          config={dataState.emailFormConfig}
                          formData={dataState.formData}
                          noForm="true"
                          parentCallback={FormBuilderCallback}
                      />
                  )}
                </S.boxHalfOverflow>
              </S.FullHeightForm>
            </S.OverflowContainer>
          </S.MidHalf>
        </S.MainContent>

        <S.BtnToolbar>
          <S.BtnToolbarContainer>
            <S.BtnToolbarHalf>
              <S.BtnLink to="/dashboard">
                <S.Btn>Back</S.Btn>
              </S.BtnLink>
              <S.Btn
                  hidden={imgsSrc.length < 0}
                  onClick={() => setDisplayImgModal(true)}
              >
                Diagnosis Image
              </S.Btn>
              <S.Btn onClick={() => setDisplayPatientModal(true)}>
                Patient Details
              </S.Btn>
            </S.BtnToolbarHalf>

            <S.BtnToolbarHalf>
              {/* <S.Btn
              className="critical"
              onClick={() => {
                setDiagnosisType("Reject");
                setDisplayRejectModel(true);
              }}
            >
              Reject
            </S.Btn>
            <S.Btn
              className="warning"
              onClick={() => {
                setDiagnosisType("Additional Info Required");
                setDisplayRejectModel(true);
              }}
            >
              Request Additional Info
            </S.Btn> */}
              <S.Btn onClick={() => setDisplayModalSuccessMsg(true)}>Send</S.Btn>
            </S.BtnToolbarHalf>
          </S.BtnToolbarContainer>
        </S.BtnToolbar>
      </S.Page>
  );
};

export default EmailSetup;
