import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, HashRouter} from "react-router-dom";
import "./index.css";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import EmailSetup from "./Pages/EmailSetup/EmailSetup";

import axios from "axios";
import Patients from "./Pages/Patients/Patients";

axios.defaults.withCredentials = true;
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<HashRouter >
    <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/emailsetup" element={<EmailSetup />} />
        <Route path="/patients" element={<Patients />} />
    </Routes>
</HashRouter>);

