import styled from "@emotion/styled";
import * as T from "../../../theme/quickspot-theme";

export const Datetime = styled.input`
  border: ${T.$border ? T.$border : "1px solid #000"};
  border-radius: ${T.$fieldBorderRadius ? T.$fieldBorderRadius : "10px"};
  color: ${T.$primaryColor ? T.$primaryColor : "#000"};
  padding: 10px;
  font-size: ${T.$fontSize ? T.$fontSize : "16px"};

  :focus-visible {
    border: ${T.$border ? T.$border : "1px solid #000"};
    border-radius: ${T.$fieldBorderRadius ? T.$fieldBorderRadius : "10px"};
    padding: 1px 2px;
    outline: unset;
    padding: 10px;
    font-size: ${T.$fontSize ? T.$fontSize : "16px"};
  }
`;
