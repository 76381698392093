import styled from "@emotion/styled";
import checkmark from "../../../images/checkmark.png";
import * as T from "../../../theme/quickspot-theme";

function getProp(props, key, defaultVal, themeProp = false) {
  return props[key] ? props[key] : themeProp ? themeProp : defaultVal;
}

export const RadioBtnGroup = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioIcon = styled.div`
  width: ${(props) => getProp(props, "dotSize", "26px")};
  height: ${(props) => getProp(props, "dotSize", "26px")};
  background-color: ${(props) =>
    props.checked
      ? getProp(props, "backgroundColor", "#0594A9", T.$primaryColor)
      : "transparent"};
  background-image: ${(props) =>
    props.checked ? "url(" + checkmark + ")" : "unset"};
  background-repeat: no-repeat;
  background-position: center;
  border-radius: calc(${(props) => getProp(props, "dotSize", "26px")} / 2);
  border: 1px solid #0594a9;
  box-sizing: border-box;
`;

export const RadioLabel = styled.label`
  padding: 0px 10px;
`;
