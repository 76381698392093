import styled from "@emotion/styled";
import profilePic from "../../../images/dermimg.jpg";
import hamburger from "../../../images/hamburger.png";

import { Link } from "react-router-dom";

export const SidebarNavContainer = styled.div`
  width: 450px;
  height: fit-content;
  border-radius: 38px;
  background: #fff;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 25px #00000033;
`;

export const DermProfileImg = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-image: url(${profilePic});
  background-position: center;
  background-size: cover;
  margin: 40px;
`;

export const DermProfileName = styled.label`
  text-align: center;
  font-size: 34px;
  margin: 0 0 50px 0;
`;

export const SidebarNavItemContainer = styled.div`
  width: 100%;
  font-size: 25px;
  display: flex;
  align-items: center;
  margin: 0 0 70px 0;
`;

export const SidebarNavIcon = styled.div`
  width: 50px;
  height: 50px;
  /* background-image: url(${hamburger}); */
  background-image: url(${(props) => (props.img ? props.img : hamburger)});
  margin: 0 20px 0 50px;
`;

export const SidebarNavItem = styled.label`
  text-align: center;
  font-size: 25px;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  color: #212529;
  text-decoration: none;

  &:hover {
    color: #0594a9;
  }
`;
