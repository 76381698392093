import React from "react";
import CornerLogo from "../../components/Simple/CornerLogo/CornerLogo";
import SidebarProfile from "../../components/Compound/SidebarProfile/SidebarProfile";
import RequestList from "../../components/Compound/RequestList/RequestList";
import Footer from "../../components/Simple/Footer/Footer";
import * as S from "./Dashboard.styles";

const Dashboard = () => {
  return (
    <S.Page>
      <CornerLogo />
      <S.MainContentContainer>
        <SidebarProfile />
        <RequestList />
      </S.MainContentContainer>
      <Footer />
    </S.Page>
  );
};

export default Dashboard;
