import React from "react";
import * as S from "./SelectList.styles";

const SelectList = ({ ...props }) => {
  const placeholder = props.placeholder ? props.placeholder : "- Select -";

  return (
    <S.SelectList {...props}>
      <option value="">{placeholder}</option>
      {props.options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.text}
        </option>
      ))}
    </S.SelectList>
  );
};

export default SelectList;
