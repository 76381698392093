import React, { useState } from "react";
import logo from "../../images/newLogoLarge.jpg";
import Footer from "../../components/Simple/Footer/Footer";
import * as S from "./Login.styles";


import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  /* //Request list
  const Auth = async (e) => {
    e.preventDefault();
    try {
        var test = await axios.get('https://vm730ndav.quickspot.online/requests', {
            email: email,
            password: password
        });
        //console.log("Test: ", test);
        //navigate("/dashboard");
    } catch (error) {
      alert(error);
        if (error.response) {
          alert(error.response.data.msg);
            setMsg(error.response.data.msg);
        }
    }
} */

  const Auth = async (e) => {
    e.preventDefault();
    try {
      console.log(process.env.REACT_APP_REACT_APP_APIURL, "dafuw");
      await axios.post(`${process.env.REACT_APP_REACT_APP_APIURL}/login`, {
        email: email,
        password: password,
      });
      navigate("/dashboard");
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <S.PageWrap>
      <S.Section>
        <S.LoginBoxContainer>
          <S.LoginBox>
            <S.Logo src={logo}></S.Logo>
            <S.Input
              placeholder="Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <S.Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <S.FlexDiv>
              {/* <S.Button  to="/dashboard">Login</S.Button> */}
              <S.Button onClick={Auth}>Login</S.Button>
            </S.FlexDiv>
          </S.LoginBox>
        </S.LoginBoxContainer>
      </S.Section>
      <Footer />
    </S.PageWrap>
  );
};

export default Login;
