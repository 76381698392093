import styled from "@emotion/styled";
import * as T from "../../../theme/quickspot-theme";

export const SelectList = styled.select`
  border: ${T.$border ? T.$border : "1px solid #0594A9"};
  border-radius: ${T.$fieldBorderRadius ? T.$fieldBorderRadius : "10px"};
  width: 40%;
  padding: 8px 10px;
  font-size: ${T.$fontSize ? T.$fontSize : "14px"};
  box-sizing: border-box;

  :focus-visible {
    border: ${T.$border ? T.$border : "1px solid #0594A9"};
    border-radius: ${T.$fieldBorderRadius ? T.$fieldBorderRadius : "10px"};
    outline: unset;
  }
`;
