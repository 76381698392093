import React from "react";
import * as S from "./Footer.styles";
const Footer = (props) => {
  return (
    <>
      <S.Footer>
        <S.FooterText>©2021 by Dr. Ilsa (Micky) Orrey</S.FooterText>
      </S.Footer>
    </>
  );
};

export default Footer;
