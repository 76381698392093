import styled from "@emotion/styled";

export const Page = styled.div`
  height: 100vh;
  margin: 0;
  display: flex;
  flex-flow: column;
`;

export const Header = styled.div`
  flex: 0 1 auto;
  display: flex;
`;

export const MainContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
`;

export const Footer = styled.div`
  flex: 0 1 40px;
`;

export const SectionHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: ${(props) => (props.padding ? props.padding : "100px 0 20px 0")};
`;

export const InputWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 20px 10px;
  margin: 0 auto;
  align-items: center;
`;

export const Label = styled.label``;
